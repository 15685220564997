<template>
    <div class="app-inner-layout__bottom-pane d-block text-center">
        <div>
            <div
                class="chat-wrapper"
                v-for="review in reviews"
                v-bind:key="review.id"
            >
                <div class="chat-box-wrapper" v-if="review.by != me">
                    <div>
                        <div class="chat-box">{{ review.message }}</div>
                        <small class="opacity-6">{{ review.created_at | formatDate }}</small>
                    </div>
                </div>

                <div
                    class="chat-box-wrapper chat-box-wrapper-right"
                    v-if="review.by == me"
                >
                    <div>
                        <div class="chat-box">{{ review.message }}</div>
                        <small class="opacity-6">{{ review.created_at | formatDate }}</small>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-block mb-0 position-relative row form-group">
            <div class="col-sm-12">
                <b-form-textarea
                                 placeholder="İletmek istediğiniz yorum/sorunuzu aşağıdaki alana yazınız ve bilgisayarınızın Enter tuşuna basınız..."
                                 type="text"
                                 v-model="message"
                                 v-on:keypress="send"
                                 class="form-control-md form-control"

                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CommentHistory",
    data() {
        return {
            message: undefined,
            me: this.$supabase.auth.currentUser.id,
            reviews: [],
        };
    },
    mounted() {
        this.fetch();
    },
    props: {
        patient: {
            type: String,
        },
        target: {
            type: String,
        },
        status: {
            type: String,
        },
    },
    computed: {
        getRole: function () {
            return this.$supabase.auth.currentUser.app_metadata.role;
        },
    },
    methods: {

        async fetch() {
            const {data} = await this.$supabase.from("review").select("*").match({
                patient: this.patient,
                target: this.target,
            });
            this.reviews = data;
        },
        async send(event) {
            if (event.key == "Enter") {
                const {data} = await this.$supabase
                    .from("review")
                    .insert([
                        {
                            target: this.target,
                            message: this.message,
                            patient: this.patient,
                        },
                    ])
                    .single();
                this.reviews.push(data);
                this.message = undefined;
            }
        },
    },
    watch: {
        target: "fetch",
        patient: "fetch",
    },
};
</script>

<style scoped>
</style>
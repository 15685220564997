<template>
  <div class="d-flex">
    <div class="header-btn pr-0">
      <div class="widget-content p-0">
        <div class="widget-content-wrapper">
          <div class="widget-content-left ml-3 mr-4 header-user-info">
            <div class="widget-heading">
              <span v-if="$store.state.fastEvaluate" class="badge badge-success">
                Hızlı Değerlendirme Modu Aktif
              </span>
            </div>
          </div>
          <div class="widget-content-left ml-3 header-user-info">
            <div class="widget-heading">
              <span v-if="user?.app_metadata" class="badge badge-secondary">
                  <span v-if="user?.app_metadata.is_super"> super</span>
                    {{user?.app_metadata.role}}
              </span>
              {{user?.email}}
            </div>
          </div>
          <div class="widget-content-left ml-3 header-user-info">
            <button class="btn-pill btn-outline btn-outline btn btn-focus" v-on:click="logout">
              Çıkış Yap
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAngleDown,
  faCalendarAlt,
  faTrashAlt,
  faCheck,
  faFileAlt,
  faCloudDownloadAlt,
  faFileExcel,
  faFilePdf,
  faFileArchive,
  faEllipsisH,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faAngleDown,
  faCalendarAlt,
  faTrashAlt,
  faCheck,
  faFileAlt,
  faCloudDownloadAlt,
  faFileExcel,
  faFilePdf,
  faFileArchive,
  faEllipsisH
);

export default {
  components: {},
  mounted() {
    this.user = this.$supabase.auth.currentUser;
  },
  data: () => ({
      user: {}
  }),

  methods: {
    async logout() {
     await this.$supabase.auth.signOut();
     this.$router.push("/login");
    }
  },
};
</script>

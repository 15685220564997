import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router);

function authorized () {
    return !!router.app.$supabase.auth.currentUser;
}

function buildBeforeGuard(roles) {
    return (to, from, next) => {
        let hasMatchingRole = true;
        if(Array.isArray(roles) && roles.length) {
            hasMatchingRole = roles.indexOf(router.app.$supabase.auth.currentUser.app_metadata.role) != -1;
        }
        if (authorized() && hasMatchingRole) {
            next();
        } else {
            next("/login");
        }
    }
}

const router =  new Router({
    scrollBehavior() {
        return window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    mode: "history",
    routes: [

        // Form
        {
            path: '/',
            name: 'form',
            component: () => import('../DemoPages/Forms/Forms.vue'),
            beforeEnter: buildBeforeGuard()
        },
        // Demographics
        {
            path: '/demographics',
            name: 'demographics',
            component: () => import('../DemoPages/Demographics/Demographics.vue'),
            beforeEnter: buildBeforeGuard(["coordinator"])
        },
        {
            path: '/demographics/:id',
            name: 'demographics-patient',
            component: () => import('../DemoPages/Demographics/Demographics.vue'),
            beforeEnter: buildBeforeGuard(["cra", "doctor", "coordinator"])
        },

        // Beware of the DOG!
        // We are using the same component for screening and review and diagnosis pages
        // Screening
        {
            path: '/screening',
            name: 'screening',
            component: () => import('../DemoPages/Screening/Screening.vue'),
            beforeEnter: buildBeforeGuard(["coordinator"]),
            meta: {
                status: ['incomplete']
            }
        },
        {
            path: '/query',
            name: 'query',
            component: () => import('../DemoPages/Screening/Screening.vue'),
            beforeEnter: buildBeforeGuard(["coordinator"]),
            meta: {
                status: ['waiting_for_cra_question']
            }
        },
        {
            path: '/detailed-search',
            name: 'detailed-search',
            component: () => import('../DemoPages/Screening/Screening.vue'),
            beforeEnter: buildBeforeGuard(["coordinator"]),
            meta: {
                status: ['incomplete', 'signed_by_coordinator', 'signed_by_cra', 'waiting_for_cra_question', 'waiting_for_coordinator_answer', 'diagnosed']
            }
        },
        // Review ()
        {
            path: '/review',
            name: 'review',
            component: () => import('../DemoPages/Screening/Screening.vue'),
            beforeEnter: buildBeforeGuard(["cra"]),
            meta: {
                status: ['signed_by_coordinator']
            }
        },
        // Review ()
        {
            path: '/query-cra',
            name: 'query-cra',
            component: () => import('../DemoPages/Screening/Screening.vue'),
            beforeEnter: buildBeforeGuard(["cra"]),
            meta: {
                status: ['waiting_for_coordinator_answer']
            }
        },
        // Diagnosis
        {
            path: '/diagnosis',
            name: 'diagnosis',
            component: () => import('../DemoPages/Screening/Screening.vue'),
            beforeEnter: buildBeforeGuard(["doctor"]),
            meta: {
                status: ['signed_by_coordinator', 'signed_by_cra', 'diagnosed']
            }
        },
        // FastScreening
        {
            path: '/fast-screening',
            name: 'fast-screening',
            component: () => import('../DemoPages/Screening/FastScreening.vue'),
            beforeEnter: buildBeforeGuard(["doctor"]),
            meta: {
                status: ['signed_by_coordinator', 'signed_by_cra', 'diagnosed']
            }
        },
        // Diagnosis by SuperDoctor // NavigationDuplicated: Avoided redundant navigation to current location: /diagnosis
        // {
        //     path: '/diagnosis',
        //     name: 'diagnosis',
        //     component: () => import('../DemoPages/Screening/Screening.vue'),
        //     beforeEnter: buildBeforeGuard(["doctor"]),
        //     meta: {
        //         status: ['signed_by_coordinator', 'signed_by_cra']
        //     }
        // },
        // Diagnosis Table
        {
            path: '/diagnosis-table',
            name: 'diagnosis-table',
            component: () => import('../DemoPages/Dashboard/DiagnosisTable.vue'),
            beforeEnter: buildBeforeGuard()
        },
        // Analysis
        {
            path: '/analysis',
            name: 'analysis',
            component: () => import('../DemoPages/Dashboard/Analysis.vue'),
            beforeEnter: buildBeforeGuard()
        },
        // Dashboard & Reporting
        {
            path: '/dashboard',
            name: 'dashboard',
            component: () => import('../DemoPages/Dashboard/Dashboard.vue'),
            beforeEnter: buildBeforeGuard()
        },


        // Dashboards
        // {
        //     path: '/analytics',
        //     name: 'analytics',
        //     component: () => import('../DemoPages/Dashboards/Analytics.vue'),
        //     beforeEnter: buildBeforeGuard()
        // },
        // {
        //     path: '/dashboards/advertisement',
        //     name: 'advertisement',
        //     component: () => import('../DemoPages/Dashboards/Advertisement.vue'),

        // },
        // {
        //     path: '/dashboards/management',
        //     name: 'management',
        //     component: () => import('../DemoPages/Dashboards/Management.vue'),

        // },
        // {
        //     path: '/dashboards/helpdesk',
        //     name: 'helpdesk',
        //     component: () => import('../DemoPages/Dashboards/Helpdesk.vue'),

        // },
        // {
        //     path: '/dashboards/monitoring',
        //     name: 'monitoring',
        //     component: () => import('../DemoPages/Dashboards/Monitoring.vue'),

        // },
        // {
        //     path: '/dashboards/crypto',
        //     name: 'crypto',
        //     component: () => import('../DemoPages/Dashboards/Crypto.vue'),

        // },
        // {
        //     path: '/dashboards/project-management',
        //     name: 'project-management',
        //     component: () => import('../DemoPages/Dashboards/ProjectManagement.vue'),

        // },
        // {
        //     path: '/dashboards/product',
        //     name: 'product',
        //     component: () => import('../DemoPages/Dashboards/Product.vue'),

        // },
        // {
        //     path: '/dashboards/statistics',
        //     name: 'statistics',
        //     component: () => import('../DemoPages/Dashboards/Statistics.vue'),

        // },

        // Pages

        {
            path: '/login',
            name: 'login',
            meta: {layout: 'userpages'},
            component: () => import('../DemoPages/UserPages/Login.vue'),

        },

        {
            path: '/register',
            name: 'register',
            meta: {layout: 'userpages'},
            component: () => import('../DemoPages/UserPages/Register.vue'),

        },

        {
            path: '/forgot-password',
            name: 'forgot-password',
            meta: {layout: 'userpages'},
            component: () => import('../DemoPages/UserPages/ForgotPassword.vue'),
        },

        {
            path: '/set-new-password',
            name: 'set-new-password',
            meta: {layout: 'userpages'},
            component: () => import('../DemoPages/UserPages/SetNewPassword.vue'),
        },

    ]
})

// http://localhost:8080/#access_token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJhdXRoZW50aWNhdGVkIiwiZXhwIjoxNjI1Njk0NDYzLCJzdWIiOiIxZWQ1ZGJkZi05NGM4LTQ4ZWItYWI5OS01MjdhY2Y3M2RiNTkiLCJlbWFpbCI6InRoZXNheXluQGdtYWlsLmNvbSIsImFwcF9tZXRhZGF0YSI6eyJwcm92aWRlciI6ImVtYWlsIn0sInVzZXJfbWV0YWRhdGEiOnt9LCJyb2xlIjoiYXV0aGVudGljYXRlZCJ9.SU_FHSQWPNBZF0x3K-qGlX1iuZGL4Zq3IHhgw1uxLRk&expires_in=3600&refresh_token=8UWPtYCdz36GQxctdmWnGg&token_type=bearer&type=recovery
router.beforeEach((to, from, next) => {
    try {
        const url = new URL(location.toString());
        url.search = to.hash.replace(/^#/, "?");
        if (url.searchParams.get("type") === "recovery") {
            next({
                path: "/set-new-password",
                query: {
                    access_token: url.searchParams.get("access_token"),
                }
            });
        }
    } catch {
        next();
    }
    next();
});

export default router;
<template>
    <div
        :class="sidebarbg"
        class="app-sidebar sidebar-shadow"
        @mouseover="toggleSidebarHover('add', 'closed-sidebar-open')"
        @mouseleave="toggleSidebarHover('remove', 'closed-sidebar-open')"
    >
        <div class="app-header__logo">
            <div class="logo-src"/>
            <div class="header__pane ml-auto">
                <button
                    type="button"
                    class="hamburger close-sidebar-btn hamburger--elastic"
                    v-bind:class="{ 'is-active': isOpen }"
                    @click="toggleBodyClass('closed-sidebar')"
                >
          <span class="hamburger-box">
            <span class="hamburger-inner"></span>
          </span>
                </button>
            </div>
        </div>

        <div class="app-sidebar-content">
            <VuePerfectScrollbar class="app-sidebar-scroll">
                <div>
                    <sidebar-menu showOneChild :menu="menus"/>
                </div>
            </VuePerfectScrollbar>
        </div>
    </div>
</template>

<script>
import {SidebarMenu} from "vue-sidebar-menu";
import VuePerfectScrollbar from "vue-perfect-scrollbar";

export default {
    components: {
        SidebarMenu,
        VuePerfectScrollbar
    },
    name: "SidebarComponent",
    data() {
        const adminMenu = [
                {
                    header: true,
                    title: "Raporlar",
                },
                {
                    title: "Klinik Çalışma Durumu",
                    icon: "pe-7s-graph2",
                    href: "/dashboard",
                },
                {
                    title: "Teşhis Sonuçları",
                    icon: "pe-7s-graph3",
                    href: "/diagnosis-table",
                },
                {
                    title: "Analiz Paneli",
                    icon: "pe-7s-graph2",
                    href: "/analysis",
                },
        ];
        const defaultMenus = [
            {
                header: true,
                title: "Formlar",
            },
            {
                title: "Protokol",
                icon: "pe-7s-note2",
                href: "/",
            },
            {
                title: "Güvenlilik Rapor Formu",
                icon: "pe-7s-note2",
                href: "/",
            },

        ];
        const menu = {
            cra: [
                {
                    header: true,
                    title: "Olgu Rapor Formu",
                },
                {
                    title: "İnceleme",
                    icon: "pe-7s-users",
                    href: "/review",
                },
                {
                    title: "Sorgulama",
                    icon: "pe-7s-hourglass",
                    href: "/query-cra",
                },
                {
                    header: true,
                    title: "Raporlar",
                },
                {
                    title: "Teşhis Sonuçları",
                    icon: "pe-7s-graph3",
                    href: "/diagnosis-table",
                },
                {
                    title: "Analiz Paneli",
                    icon: "pe-7s-graph2",
                    href: "/dashboard",
                },
            ],
            coordinator: [
                {
                    header: true,
                    title: "Olgu Rapor Formu",
                },
                {
                    title: "Gönüllü Bilgileri",
                    icon: "pe-7s-user",
                    href: "/demographics",
                },
                {
                    title: "İnceleme",
                    icon: "pe-7s-users",
                    href: "/screening",
                },
                {
                    title: "Sorgular",
                    icon: "pe-7s-hourglass",
                    href: "/query",
                },
                {
                    title: "Detaylı Arama",
                    icon: "pe-7s-search",
                    href: "/detailed-search",
                }

            ],
            doctor: [
                {
                    header: true,
                    title: "Doktor Paneli",
                },
                {
                    title: "Doktor Teşhisleri",
                    icon: "pe-7s-users",
                    href: "/diagnosis",
                },
                {
                    title: "Hızlı Değerlendir",
                    icon: "pe-7s-gleam",
                    href: "/fast-screening",
                }
            ],
            empty: []
        };
        return {
            isOpen: false,
            sidebarActive: false,
            collapsed: true,
            windowWidth: 0,
            menus: [
                ...defaultMenus,
                ...menu[this.$supabase.auth.currentSession?.user.app_metadata.role !== undefined ? 
                        this.$supabase.auth.currentSession?.user.app_metadata.role : "empty"],
                ...this.$supabase.auth.currentSession?.user.app_metadata?.is_moderator ? adminMenu : []
            ],
        };
    },
    props: {
        sidebarbg: String,
    },
    methods: {
        toggleBodyClass(className) {
            const el = document.body;
            this.isOpen = !this.isOpen;

            if (this.isOpen) {
                el.classList.add(className);
            } else {
                el.classList.remove(className);
            }
        },
        toggleSidebarHover(add, className) {
            const el = document.body;
            this.sidebarActive = !this.sidebarActive;

            this.windowWidth = document.documentElement.clientWidth;

            if (this.windowWidth > "992") {
                if (add === "add") {
                    el.classList.add(className);
                } else {
                    el.classList.remove(className);
                }
            }
        },
        getWindowWidth() {
            const el = document.body;

            this.windowWidth = document.documentElement.clientWidth;

            if (this.windowWidth < "1350") {
                el.classList.add("closed-sidebar", "closed-sidebar-md");
            } else {
                el.classList.remove("closed-sidebar", "closed-sidebar-md");
            }
        },
    },
    mounted() {
        this.$nextTick(function () {
            window.addEventListener("resize", this.getWindowWidth);

            //Init
            this.getWindowWidth();
        });
    },

    beforeDestroy() {
        window.removeEventListener("resize", this.getWindowWidth);
    },
};
</script>

<template>
    <div class="row form-area">
        <div class="col-md-12">
            <template>
                <div role="tablist">
                    <div class="card-body">
                        <b-form class="">
                            <!-- CommentHistory Modal  -->
                            <b-modal @hide="getCraStatus" id="commentHistoryModal" hide-footer size="md"
                                     title="Yorum">
                                <comment-history :patient="patient_id" :target="currentHistoryTarget"></comment-history>
                            </b-modal>

                            <!-- Demographics -->
                            <b-card v-show="getRole === 'coordinator' || getRole === 'cra' || getRole === 'doctor' "
                                    no-body class="mb-1">
                                <b-card-header
                                    :style="getRole === 'coordinator' || getRole === 'cra' ? Object.keys(this.status).map((el) => el.split('.')[0]).includes('demographics') ? {'background-color': '#DBA29B'} : { 'background-color': '#ffffff !important' } : ''"
                                    header-tag="header"
                                    class="p-1"
                                    role="tab"
                                    @click="jumpToSection(1)"
                                >
                                    <div class="p-3 d-block">
                                        <h5 class="card-title font-weight-bold d-inline">Demografik Bilgiler</h5>
                                    </div>
                                </b-card-header>
                                <b-collapse
                                    id="accordion1"
                                    :visible="step === 0"
                                    accordion="my-accordion"
                                    role="tabpanel"
                                >
                                    <b-card-body>
                                        <!-- Name - Surname -->
                                        <div class="position-relative row">
                                            <div class="col-sm-12 form-group">
                                                <div class="position-relative row form-group">
                                                    <label
                                                        for="name_surname"
                                                        class="col-sm-4 col-form-label"
                                                    >Ad Soyad *
                                                    </label>
                                                    <div class="col-sm-6">
                                                        <input
                                                            placeholder="Örneğin: N. S. (Sadece baş harfleri girilmeli)"
                                                            :readonly="getRole === 'doctor' || getRole === 'cra' || isModerator"
                                                            v-model="demographics.name_surname"
                                                            name="name_surname"
                                                            id="name_surname"
                                                            type="text"
                                                            class="form-control"
                                                            :style="buttonVisible ? {'background-color': (status['demographics.name_surname'] ?  (status['demographics.name_surname'].role === 'cra' ? '#DBA29B' : '#3ac47d') : '#ffffff')} : { 'background-color': '#ffffff !important' }"
                                                        />

                                                    </div>
                                                    <div class="col-sm-2 float-left">
                                                        <b-button v-if="buttonVisible"
                                                                  style="background-color: white; border: 0 !important;"
                                                                  v-on:click="showComments('demographics.name_surname')">
                                                            <b-icon icon="question-circle-fill"
                                                                    style="width: 20px; height: 20px;"
                                                                    :variant="status['demographics.name_surname'] ?  (status['demographics.name_surname'].role === 'cra' ? 'danger':'success') : 'primary'"
                                                            ></b-icon>
                                                        </b-button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 form-group">
                                                <div class="position-relative row form-group">
                                                    <label
                                                        for="protocol_no"
                                                        class="col-sm-4 col-form-label"
                                                    >Protokol Numarası *
                                                    </label>
                                                    <div class="col-sm-6">
                                                        <input
                                                            :readonly="getRole === 'doctor' || getRole === 'cra' || isModerator "
                                                            v-model="demographics.protocol_no"
                                                            v-on:keyup="checkProtocolNoUniqueness"
                                                            name="protocol_no"
                                                            id="protocol_no"
                                                            placeholder="Lütfen protokol numarasini giriniz."
                                                            type="text"
                                                            class="form-control"
                                                            :style="buttonVisible ? {'background-color': (status['demographics.protocol_no'] ?  (status['demographics.protocol_no'].role === 'cra' ? '#DBA29B' : '#3ac47d') : '#ffffff')} : { 'background-color': '#ffffff !important' }"
                                                        />

                                                        <small
                                                            class="text-danger"
                                                            v-if="!isProtocolNoUnique"
                                                        >Bu protokol numarasi zaten kullaniliyor.</small
                                                        >
                                                    </div>
                                                    <div class="col-sm-2 float-left">
                                                        <b-button v-if="buttonVisible"
                                                                  style="background-color: white; border: 0 !important;"
                                                                  v-on:click="showComments('demographics.protocol_no')">
                                                            <b-icon icon="question-circle-fill"
                                                                    style="width: 20px; height: 20px;"
                                                                    :variant="status['demographics.protocol_no'] ?  (status['demographics.protocol_no'].role === 'cra' ? 'danger':'success') : 'primary'"
                                                            >
                                                            </b-icon>
                                                        </b-button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Date fo Visit -->
                                        <div class="position-relative row form-group">
                                            <label class="col-sm-4 col-form-label"
                                            >Ziyaret Tarihi *</label
                                            >
                                            <div class="col-sm-6">
                                                <v-flex xs12 sm12 md12>
                                                    <v-menu
                                                        v-model="menu2"
                                                        :close-on-content-click="false"
                                                        :nudge-right="40"
                                                        lazy
                                                        transition="scale-transition"
                                                        offset-y
                                                        full-width
                                                        min-width="290px"
                                                    >
                                                        <template v-slot:activator="{ on }">
                                                            <v-text-field
                                                                :disabled="getRole === 'doctor' || getRole === 'cra' || isModerator"
                                                                v-model="demographics.date_of_visit"
                                                                label=""
                                                                prepend-icon="event"
                                                                v-on="on"
                                                                :style="buttonVisible ? {'color': (status['demographics.date_of_visit'] ?  (status['demographics.date_of_visit'].role === 'cra' ? '#DBA29B' : '#3ac47d') : '#333333')} : { 'color': '#333333 !important' }"
                                                            ></v-text-field>
                                                        </template>
                                                        <v-date-picker
                                                            v-model="demographics.date_of_visit"
                                                            @input="menu2 = false"
                                                            required
                                                        ></v-date-picker>
                                                    </v-menu>
                                                </v-flex>
                                            </div>
                                            <div class="col-sm-2 float-left">
                                                <b-button v-if="buttonVisible"
                                                          style="background-color: white; border: 0 !important;"
                                                          v-on:click="showComments('demographics.date_of_visit')">
                                                    <b-icon icon="question-circle-fill"
                                                            style="width: 20px; height: 20px;"
                                                            :variant="status['demographics.date_of_visit'] ?  (status['demographics.date_of_visit'].role === 'cra' ? 'danger':'success') : 'primary'"
                                                    >

                                                    </b-icon>
                                                </b-button>
                                            </div>
                                        </div>

                                        <!-- Gender -->
                                        <div class="position-relative row">
                                            <div class="col-sm-12 form-group">
                                                <div class="row form-group">
                                                    <label
                                                        for="genderSelect"
                                                        class="col-sm-4 col-form-label"
                                                    >Cinsiyet *
                                                    </label>
                                                    <div class="col-sm-6">
                                                        <select
                                                            :disabled="getRole === 'doctor' || getRole === 'cra' || isModerator"
                                                            name="genderSelect"
                                                            v-model="demographics.gender"
                                                            id="genderSelect"
                                                            class="form-control"
                                                            :style="buttonVisible ? {'background-color': (status['demographics.gender'] ?  (status['demographics.gender'].role === 'cra' ? '#DBA29B' : '#3ac47d') : '#ffffff')} : { 'background-color': '#ffffff !important' }"
                                                        >
                                                            <option value="male">Erkek</option>
                                                            <option value="female">Kadın</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-sm-2 float-left">
                                                        <b-button v-if="buttonVisible"
                                                                  style="background-color: white; border: 0 !important;"
                                                                  v-on:click="showComments('demographics.gender')">
                                                            <b-icon icon="question-circle-fill"
                                                                    style="width: 20px; height: 20px;"
                                                                    :variant="status['demographics.gender'] ?  (status['demographics.gender'].role === 'cra' ? 'danger':'success') : 'primary'"
                                                            >
                                                            </b-icon>
                                                        </b-button>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- Age Input Text -->
                                            <div class="col-sm-12 form-group">
                                                <div class="position-relative row form-group">
                                                    <label
                                                        for="age"
                                                        class="col-sm-4 col-form-label"
                                                    >Yaş *
                                                    </label>
                                                    <div class="col-sm-6">
                                                        <b-form-group id="age" label-for="age">
                                                            <b-form-input
                                                                :readonly="getRole === 'doctor' || getRole === 'cra' || isModerator"
                                                                v-model.number="demographics.age"
                                                                name="age"
                                                                id="age"
                                                                placeholder=""
                                                                type="text"
                                                                class="form-control"
                                                                aria-describedby="ageLiveFeedback"
                                                                :state="ageControl"
                                                                :style="buttonVisible ? {'background-color': (status['demographics.age'] ?  (status['demographics.age'].role === 'cra' ? '#DBA29B' : '#3ac47d') : '#ffffff')} : { 'background-color': '#ffffff !important' }"

                                                            ></b-form-input>
                                                            <b-form-invalid-feedback id="ageLiveFeedback">
                                                                Yaş zorunlu bir alandır ve 18'den büyük olmalıdır.
                                                            </b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </div>
                                                    <div class="col-sm-2 float-left">
                                                        <b-button v-if="buttonVisible"
                                                                  style="background-color: white; border: 0 !important;"
                                                                  v-on:click="showComments('demographics.age')">
                                                            <b-icon icon="question-circle-fill"
                                                                    style="width: 20px; height: 20px;"
                                                                    :variant="status['demographics.age'] ?  (status['demographics.age'].role === 'cra' ? 'danger':'success') : 'primary'"
                                                            ></b-icon>
                                                        </b-button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Weight, Height & BMI -->
                                        <div class="position-relative row">
                                            <div class="col-sm-12 form-group">
                                                <div class="row form-group">
                                                    <label for="weight" class="col-sm-4 col-form-label"
                                                    >Kilo *</label
                                                    >
                                                    <div class="col-sm-6">
                                                        <input
                                                            :readonly="getRole === 'doctor' || getRole === 'cra' || isModerator"
                                                            v-model.number="demographics.weight"
                                                            name="weight"
                                                            id="weight"
                                                            placeholder=""
                                                            type="number"
                                                            class="form-control"
                                                            :style="buttonVisible ? {'background-color': (status['demographics.weight'] ?  (status['demographics.weight'].role === 'cra' ? '#DBA29B' : '#3ac47d') : '#ffffff')} : { 'background-color': '#ffffff !important' }"

                                                        />
                                                    </div>
                                                    <div class="col-sm-2 float-left">
                                                        <b-button v-if="buttonVisible"
                                                                  style="background-color: white; border: 0 !important;"
                                                                  v-on:click="showComments('demographics.weight')">
                                                            <b-icon icon="question-circle-fill"
                                                                    style="width: 20px; height: 20px;"
                                                                    :variant="status['demographics.weight'] ?  (status['demographics.weight'].role === 'cra' ? 'danger':'success') : 'primary'"
                                                            ></b-icon>
                                                        </b-button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 form-group">
                                                <div class="position-relative row form-group">
                                                    <label for="height" class="col-sm-4 col-form-label">
                                                        Boy(cm) *
                                                    </label>
                                                    <div class="col-sm-6">
                                                        <b-form-group id="height" label-for="height">
                                                            <b-form-input
                                                                :readonly="getRole === 'doctor' || getRole === 'cra' || isModerator"
                                                                v-model.number="demographics.height"
                                                                name="height"
                                                                id="height"
                                                                placeholder=""
                                                                required
                                                                type="number"
                                                                class="form-control"
                                                                aria-describedby="emailLiveFeedback"
                                                                :state="heightControl"
                                                                :style="buttonVisible ? {'background-color': (status['demographics.height'] ?  (status['demographics.height'].role === 'cra' ? '#DBA29B' : '#3ac47d') : '#ffffff')} : { 'background-color': '#ffffff !important' }"
                                                            ></b-form-input>
                                                            <b-form-invalid-feedback id="heightLiveFeedback">
                                                                Boy zorunlu bir alandır ve 100cm den uzun olmalıdır.
                                                            </b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </div>
                                                    <div class="col-sm-2 float-left">
                                                        <b-button v-if="buttonVisible"
                                                                  style="background-color: white; border: 0 !important;"
                                                                  v-on:click="showComments('demographics.height')">
                                                            <b-icon icon="question-circle-fill"
                                                                    style="width: 20px; height: 20px;"
                                                                    :variant="status['demographics.height'] ?  (status['demographics.height'].role === 'cra' ? 'danger':'success') : 'primary'"
                                                            ></b-icon>
                                                        </b-button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 form-group">
                                                <div class="position-relative row form-group">
                                                    <label for="bmi" class="col-sm-4 col-form-label"
                                                    >Vücut Kitle Endeksi *</label
                                                    >
                                                    <div class="col-sm-6">
                                                        <input
                                                            :readonly="true"
                                                            v-model="bmiCalculation"
                                                            name="bmi"
                                                            id="bmi"
                                                            placeholder=""
                                                            type="number"
                                                            class="form-control"
                                                            :style="buttonVisible ? {'background-color': (status['demographics.bmi'] ?  (status['demographics.bmi'].role === 'cra' ? '#DBA29B' : '#3ac47d') : '#ffffff')} : { 'background-color': '#ffffff !important' }"
                                                        />
                                                    </div>
                                                    <div class="col-sm-2 float-left">
                                                        <b-button v-if="buttonVisible"
                                                                  style="background-color: white; border: 0 !important;"
                                                                  v-on:click="showComments('demographics.bmi')">
                                                            <b-icon icon="question-circle-fill"
                                                                    style="width: 20px; height: 20px;"
                                                                    :variant="status['demographics.bmi'] ?  (status['demographics.bmi'].role === 'cra' ? 'danger':'success') : 'primary'"
                                                            ></b-icon>
                                                        </b-button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 form-group">
                                                <div class="position-relative row form-group">
                                                    <div class="col-sm-12 form-group">
                                                        <div class="row form-group">
                                                            <div class="col-md-4">
                                                                <label for="did_come_from" class="col-form-label form-check-label">
                                                                    Gönüllü nereden yönlendirildi? *
                                                                </label>
                                                            </div>
                                                            <div class="col-sm-6">
                                                                <select
                                                                    :disabled="getRole === 'doctor' || getRole === 'cra' || isModerator"
                                                                    name="did_come_from"
                                                                    v-model="demographics.did_come_from"
                                                                    id="did_come_from"
                                                                    class="form-control"
                                                                    :style="buttonVisible ? {'background-color': (status['demographics.did_come_from'] ?  (status['demographics.did_come_from'].role === 'cra' ? '#DBA29B' : '#3ac47d') : '#ffffff')} : { 'background-color': '#ffffff !important' }"
                                                                >
                                                                    <option value="consultation">Konsültasyon</option>
                                                                    <option value="eye_clinic">Göz Kliniği</option>
                                                                </select>
                                                            </div>
                                                            <div class="col-sm-2 float-left">
                                                                <b-button v-if="buttonVisible"
                                                                        style="background-color: white; border: 0 !important;"
                                                                        v-on:click="showComments('demographics.did_come_from')">
                                                                    <b-icon icon="question-circle-fill"
                                                                            style="width: 20px; height: 20px;"
                                                                            :variant="status['demographics.did_come_from'] ?  (status['demographics.did_come_from'].role === 'cra' ? 'danger':'success') : 'primary'"
                                                                    >
                                                                    </b-icon>
                                                                </b-button>
                                                            </div>
                                                            <div class="col-sm-2"></div>
                                                            <div class="col-sm-2 float-left">
                                                                <b-button v-if="buttonVisible"
                                                                          style="background-color: white; border: 0 !important;"
                                                                          v-on:click="showComments('demographics.did_come_from')">
                                                                    <b-icon icon="question-circle-fill"
                                                                            style="width: 20px; height: 20px;"
                                                                            :variant="status['demographics.did_come_from'] ?  (status['demographics.did_come_from'].role === 'cra' ? 'danger':'success') : 'primary'"
                                                                    ></b-icon>
                                                                </b-button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 form-group">
                                                <div class="position-relative row form-group">
                                                    <div class="col-sm-12 form-group">
                                                        <div class="row form-group">
                                                            <div class="col-md-4">
                                                                <label for="single_eye" class="col-form-label form-check-label">
                                                                    Gönüllünün tek gözü mü çalışmaya dahil? *
                                                                </label>
                                                            </div>
                                                            <div class="col-md-1">
                                                                <div class="position-relative form-check">
                                                                    <label class="form-check-label"
                                                                        :style="buttonVisible ? {'background-color': (status['demographics.single_eye'] ?  (status['demographics.single_eye'].role === 'cra' ? '#DBA29B' : '#3ac47d') : '#ffffff')} : { 'background-color': '#ffffff !important' }"
                                                                    ><input
                                                                        :disabled="getRole === 'doctor' || getRole === 'cra' || isModerator"
                                                                        v-model="demographics.single_eye"
                                                                        v-bind:value="true"
                                                                        name="single_eye"
                                                                        type="radio"
                                                                        class="form-check-input"
                                                                    />
                                                                        Evet
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-1">
                                                                <div class="position-relative form-check">
                                                                    <label class="form-check-label"
                                                                        :style="buttonVisible ? {'background-color': (status['demographics.single_eye'] ?  (status['demographics.single_eye'].role === 'cra' ? '#DBA29B' : '#3ac47d') : '#ffffff')} : { 'background-color': '#ffffff !important' }"
                                                                    ><input
                                                                        :disabled="getRole === 'doctor' || getRole === 'cra' || isModerator"
                                                                        v-model="demographics.single_eye"
                                                                        v-bind:value="false"
                                                                        name="single_eye"
                                                                        type="radio"
                                                                        class="form-check-input"
                                                                    />
                                                                        Hayır
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-2 float-left">
                                                                <b-button v-if="buttonVisible"
                                                                        style="background-color: white; border: 0 !important;"
                                                                        v-on:click="showComments('demographics.single_eye')">
                                                                    <b-icon icon="question-circle-fill"
                                                                            style="width: 20px; height: 20px;"
                                                                            :variant="status['demographics.single_eye'] ?  (status['demographics.single_eye'].role === 'cra' ? 'danger':'success') : 'primary'"
                                                                    >
                                                                    </b-icon>
                                                                </b-button>
                                                            </div>
                                                            <div class="col-sm-2"></div>
                                                            <div class="col-sm-2 float-left">
                                                                <b-button v-if="buttonVisible"
                                                                          style="background-color: white; border: 0 !important;"
                                                                          v-on:click="showComments('demographics.single_eye')">
                                                                    <b-icon icon="question-circle-fill"
                                                                            style="width: 20px; height: 20px;"
                                                                            :variant="status['demographics.single_eye'] ?  (status['demographics.single_eye'].role === 'cra' ? 'danger':'success') : 'primary'"
                                                                    ></b-icon>
                                                                </b-button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 form-group">
                                                <div class="position-relative row form-group">
                                                    <div class="col-sm-12 form-group">
                                                        <div class="row form-group">
                                                            <div class="col-md-4">
                                                                <label for="has_dripped" class="col-form-label form-check-label">
                                                                    Fundus çekimleri için damla damlatıldı mı? *
                                                                </label>
                                                            </div>
                                                            <div class="col-md-1">
                                                                <div class="position-relative form-check">
                                                                    <label class="form-check-label"
                                                                        :style="buttonVisible ? {'background-color': (status['demographics.has_dripped'] ?  (status['demographics.has_dripped'].role === 'cra' ? '#DBA29B' : '#3ac47d') : '#ffffff')} : { 'background-color': '#ffffff !important' }"
                                                                    ><input
                                                                        :disabled="getRole === 'doctor' || getRole === 'cra' || isModerator"
                                                                        v-model="demographics.has_dripped"
                                                                        v-bind:value="true"
                                                                        name="has_dripped"
                                                                        type="radio"
                                                                        class="form-check-input"
                                                                    />
                                                                        Evet
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-1">
                                                                <div class="position-relative form-check">
                                                                    <label class="form-check-label"
                                                                        :style="buttonVisible ? {'background-color': (status['demographics.has_dripped'] ?  (status['demographics.has_dripped'].role === 'cra' ? '#DBA29B' : '#3ac47d') : '#ffffff')} : { 'background-color': '#ffffff !important' }"
                                                                    ><input
                                                                        :disabled="getRole === 'doctor' || getRole === 'cra' || isModerator"
                                                                        v-model="demographics.has_dripped"
                                                                        v-bind:value="false"
                                                                        name="has_dripped"
                                                                        type="radio"
                                                                        class="form-check-input"
                                                                    />
                                                                        Hayır
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-2 float-left">
                                                                <b-button v-if="buttonVisible"
                                                                        style="background-color: white; border: 0 !important;"
                                                                        v-on:click="showComments('demographics.has_dripped')">
                                                                    <b-icon icon="question-circle-fill"
                                                                            style="width: 20px; height: 20px;"
                                                                            :variant="status['demographics.has_dripped'] ?  (status['demographics.has_dripped'].role === 'cra' ? 'danger':'success') : 'primary'"
                                                                    >
                                                                    </b-icon>
                                                                </b-button>
                                                            </div>
                                                            <div class="col-sm-2"></div>
                                                            <div class="col-sm-2 float-left">
                                                                <b-button v-if="buttonVisible"
                                                                          style="background-color: white; border: 0 !important;"
                                                                          v-on:click="showComments('demographics.has_dripped')">
                                                                    <b-icon icon="question-circle-fill"
                                                                            style="width: 20px; height: 20px;"
                                                                            :variant="status['demographics.has_dripped'] ?  (status['demographics.has_dripped'].role === 'cra' ? 'danger':'success') : 'primary'"
                                                                    ></b-icon>
                                                                </b-button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 form-group">
                                                <div class="position-relative row form-group">
                                                    <div class="col-sm-12 form-group">
                                                        <div class="row form-group">
                                                            <div class="col-md-4">
                                                                <label for="has_cigarette" class="col-form-label form-check-label">
                                                                    Gönüllü sigara kullanıyor mu? *
                                                                </label>
                                                            </div>
                                                            <div class="col-md-1">
                                                                <div class="position-relative form-check">
                                                                    <label class="form-check-label"
                                                                        :style="buttonVisible ? {'background-color': (status['demographics.has_cigarette'] ?  (status['demographics.has_cigarette'].role === 'cra' ? '#DBA29B' : '#3ac47d') : '#ffffff')} : { 'background-color': '#ffffff !important' }"
                                                                    ><input
                                                                        :disabled="getRole === 'doctor' || getRole === 'cra' || isModerator"
                                                                        v-model="demographics.has_cigarette"
                                                                        v-bind:value="true"
                                                                        name="has_cigarette"
                                                                        type="radio"
                                                                        class="form-check-input"
                                                                    />
                                                                        Evet
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-1">
                                                                <div class="position-relative form-check">
                                                                    <label class="form-check-label"
                                                                        :style="buttonVisible ? {'background-color': (status['demographics.has_cigarette'] ?  (status['demographics.has_cigarette'].role === 'cra' ? '#DBA29B' : '#3ac47d') : '#ffffff')} : { 'background-color': '#ffffff !important' }"
                                                                    ><input
                                                                        :disabled="getRole === 'doctor' || getRole === 'cra' || isModerator"
                                                                        v-model="demographics.has_cigarette"
                                                                        v-bind:value="false"
                                                                        name="has_cigarette"
                                                                        type="radio"
                                                                        class="form-check-input"
                                                                    />
                                                                        Hayır
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-2 float-left">
                                                                <b-button v-if="buttonVisible"
                                                                        style="background-color: white; border: 0 !important;"
                                                                        v-on:click="showComments('demographics.has_cigarette')">
                                                                    <b-icon icon="question-circle-fill"
                                                                            style="width: 20px; height: 20px;"
                                                                            :variant="status['demographics.has_cigarette'] ?  (status['demographics.has_cigarette'].role === 'cra' ? 'danger':'success') : 'primary'"
                                                                    >
                                                                    </b-icon>
                                                                </b-button>
                                                            </div>
                                                            <div class="col-sm-2"></div>
                                                            <div class="col-sm-2 float-left">
                                                                <b-button v-if="buttonVisible"
                                                                          style="background-color: white; border: 0 !important;"
                                                                          v-on:click="showComments('demographics.has_cigarette')">
                                                                    <b-icon icon="question-circle-fill"
                                                                            style="width: 20px; height: 20px;"
                                                                            :variant="status['demographics.has_cigarette'] ?  (status['demographics.has_cigarette'].role === 'cra' ? 'danger':'success') : 'primary'"
                                                                    ></b-icon>
                                                                </b-button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 form-group">
                                                <div class="position-relative row form-group">
                                                    <div class="col-sm-12 form-group">
                                                        <div class="row form-group">
                                                            <div class="col-md-4">
                                                                <label for="has_problem" class="col-form-label form-check-label">
                                                                    Çalışma esnasında herhangi bir olumsuz olay yaşandı mı? EVET ise, lütfen "Güvenlilik Rapor Formu" doldurun. (Araştırma ekibinin olaydan haberdar edilmesinden sonraki 24 saat içinde Sponsora bildirilmelidir.) *
                                                                </label>
                                                            </div>
                                                            <div class="col-md-1">
                                                                <div class="position-relative form-check">
                                                                    <label class="form-check-label"
                                                                        :style="buttonVisible ? {'background-color': (status['demographics.has_problem'] ?  (status['demographics.has_problem'].role === 'cra' ? '#DBA29B' : '#3ac47d') : '#ffffff')} : { 'background-color': '#ffffff !important' }"
                                                                    ><input
                                                                        :disabled="getRole === 'doctor' || getRole === 'cra' || isModerator"
                                                                        v-model="demographics.has_problem"
                                                                        v-bind:value="true"
                                                                        name="has_problem"
                                                                        type="radio"
                                                                        class="form-check-input"
                                                                    />
                                                                        Evet
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-1">
                                                                <div class="position-relative form-check">
                                                                    <label class="form-check-label"
                                                                        :style="buttonVisible ? {'background-color': (status['demographics.has_problem'] ?  (status['demographics.has_cigarette'].role === 'cra' ? '#DBA29B' : '#3ac47d') : '#ffffff')} : { 'background-color': '#ffffff !important' }"
                                                                    ><input
                                                                        :disabled="getRole === 'doctor' || getRole === 'cra' || isModerator"
                                                                        v-model="demographics.has_problem"
                                                                        v-bind:value="false"
                                                                        name="has_problem"
                                                                        type="radio"
                                                                        class="form-check-input"
                                                                    />
                                                                        Hayır
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-2 float-left">
                                                                <b-button v-if="buttonVisible"
                                                                        style="background-color: white; border: 0 !important;"
                                                                        v-on:click="showComments('demographics.has_problem')">
                                                                    <b-icon icon="question-circle-fill"
                                                                            style="width: 20px; height: 20px;"
                                                                            :variant="status['demographics.has_problem'] ?  (status['demographics.has_problem'].role === 'cra' ? 'danger':'success') : 'primary'"
                                                                    >
                                                                    </b-icon>
                                                                </b-button>
                                                            </div>
                                                            <div class="col-sm-2"></div>
                                                            <div class="col-sm-2 float-left">
                                                                <b-button v-if="buttonVisible"
                                                                          style="background-color: white; border: 0 !important;"
                                                                          v-on:click="showComments('demographics.has_problem')">
                                                                    <b-icon icon="question-circle-fill"
                                                                            style="width: 20px; height: 20px;"
                                                                            :variant="status['demographics.has_problem'] ?  (status['demographics.has_problem'].role === 'cra' ? 'danger':'success') : 'primary'"
                                                                    ></b-icon>
                                                                </b-button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-card-body>
                                    <div class="float-right mb-5 mr-2">
                                        <b-button
                                            size="md"
                                            :disabled="$v.demographics.$invalid"
                                            variant="primary"
                                            v-on:click="upsertPatient"
                                            class="btn btn-wide btn-primary mr-3"
                                            type="button"
                                            v-if="getRole === 'coordinator' && (this.demographics.status !== 'signed_by_cra') && (this.demographics.status !== 'diagnosed')"

                                        >
                                            <span v-show="!loading"> Kaydet & Devam </span>
                                            <div v-show="loading" class="spinner-border spinner-border-sm"
                                                 role="status">
                                                <span class="sr-only">Yükleniyor...</span>
                                            </div>
                                        </b-button>
                                    </div>
                                </b-collapse>
                            </b-card>

                            <!-- Diabetes History -->
                            <b-card v-if="!$v.demographics.$invalid" v-show="getRole === 'coordinator' || getRole === 'cra' || getRole === 'doctor'"
                                    no-body class="mb-1">
                                <b-card-header
                                    :style="getRole === 'coordinator' || getRole === 'cra' ? Object.keys(this.status).map((el) => el.split('.')[0]).includes('medical_history') ? {'background-color': '#DBA29B'} : { 'background-color': '#ffffff !important' } : ''"
                                    header-tag="header"
                                    class="p-1"
                                    role="tab"
                                    @click="jumpToSection(2)"
                                >
                                    <div class="p-3 d-block">
                                        <h5 class="card-title font-weight-bold d-inline">TIBBİ GEÇMİŞ</h5>
                                    </div>
                                </b-card-header>
                                <b-collapse
                                    id="accordion2"
                                    :visible="step === 1"
                                    accordion="my-accordion"
                                    role="tabpanel"
                                >
                                    <b-card-body>
                                        <!-- Question 1 -->
                                        <div class="position-relative row">
                                            <div class="col-sm-12 form-group">
                                                <div class="row form-group">
                                                    <label
                                                        for="is_diabetes"
                                                        class="col-sm-6 col-form-label"
                                                    >Gönüllü diyabet hastası mı? *
                                                    </label>
                                                    <div class="col-md-1">
                                                        <div class="position-relative form-check">
                                                            <label class="form-check-label"
                                                                   :style="buttonVisible ? {'background-color': (status['medical_history.is_diabetes'] ?  (status['demographics.is_diabetes'].role === 'cra' ? '#DBA29B' : '#3ac47d') : '#ffffff')} : { 'background-color': '#ffffff !important' }"
                                                            ><input
                                                                :disabled="getRole === 'doctor' || getRole === 'cra' || isModerator"
                                                                v-model="medical_history.is_diabetes"
                                                                v-bind:value="true"
                                                                name="is_diabetes"
                                                                type="radio"
                                                                class="form-check-input"
                                                            />
                                                                Evet
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-1">
                                                        <div class="position-relative form-check">
                                                            <label class="form-check-label"
                                                                   :style="buttonVisible ? {'background-color': (status['medical_history.is_diabetes'] ?  (status['demographics.is_diabetes'].role === 'cra' ? '#DBA29B' : '#3ac47d') : '#ffffff')} : { 'background-color': '#ffffff !important' }"
                                                            ><input
                                                                :disabled="getRole === 'doctor' || getRole === 'cra' || isModerator"
                                                                v-model="medical_history.is_diabetes"
                                                                v-bind:value="false"
                                                                name="is_diabetes"
                                                                type="radio"
                                                                class="form-check-input"
                                                            />
                                                                Hayır
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-2 float-left">
                                                        <b-button v-if="buttonVisible"
                                                                style="background-color: white; border: 0 !important;"
                                                                v-on:click="showComments('medical_history.is_diabetes')">
                                                            <b-icon icon="question-circle-fill"
                                                                    style="width: 20px; height: 20px;"
                                                                    :variant="status['medical_history.is_diabetes'] ?  (status['medical_history.is_diabetes'].role === 'cra' ? 'danger':'success') : 'primary'"
                                                            >
                                                            </b-icon>
                                                        </b-button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row form-group">
                                            <div class="col-md-10">
                                                <input
                                                    v-model="medical_history.diabetes_description"
                                                    :readonly="getRole === 'doctor' || getRole === 'cra' || isModerator"
                                                    name="diabetes_description"
                                                    id="diabetes_description"
                                                    v-if="medical_history.is_diabetes"
                                                    placeholder="Lütfen gönüllü diyabeti hakkında bilgi veriniz."
                                                    type="text"
                                                    class="form-control"
                                                    :style="buttonVisible ? {'background-color': (status['medical_history.diabetes_description'] ?  (status['medical_history.diabetes_description'].role === 'cra' ? '#DBA29B' : '#3ac47d') : '#ffffff')} : { 'background-color': '#ffffff !important' }"

                                                />
                                            </div>
                                            <div class="col-sm-2 float-left"
                                                v-if="medical_history.is_diabetes"
                                            >
                                                <b-button v-if="buttonVisible"
                                                        style="background-color: white; border: 0 !important;"
                                                        v-on:click="showComments('medical_history.diabetes_description')">
                                                    <b-icon icon="question-circle-fill"
                                                            style="width: 20px; height: 20px;"
                                                            :variant="status['medical_history.diabetes_description'] ?  (status['medical_history.diabetes_description'].role === 'cra' ? 'danger':'success') : 'primary'"
                                                    ></b-icon>
                                                </b-button>
                                            </div>
                                        </div>
                                        <!-- Question 2 -->
                                        <div class="position-relative row">
                                            <div class="col-sm-12 form-group">
                                                <div class="row form-group">
                                                    <label
                                                        for="eye_pressure"
                                                        class="col-sm-6 col-form-label"
                                                    >Gönüllü tansiyon hastası mı? *
                                                    </label>
                                                    <div class="col-md-1">
                                                        <div class="position-relative form-check">
                                                            <label class="form-check-label"
                                                                   :style="buttonVisible ? {'background-color': (status['medical_history.eye_pressure'] ?  (status['demographics.eye_pressure'].role === 'cra' ? '#DBA29B' : '#3ac47d') : '#ffffff')} : { 'background-color': '#ffffff !important' }"
                                                            ><input
                                                                :disabled="getRole === 'doctor' || getRole === 'cra' || isModerator"
                                                                v-model="medical_history.eye_pressure"
                                                                v-bind:value="true"
                                                                name="eye_pressure"
                                                                type="radio"
                                                                class="form-check-input"
                                                            />
                                                                Evet
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-1">
                                                        <div class="position-relative form-check">
                                                            <label class="form-check-label"
                                                                   :style="buttonVisible ? {'background-color': (status['medical_history.eye_pressure'] ?  (status['demographics.eye_pressure'].role === 'cra' ? '#DBA29B' : '#3ac47d') : '#ffffff')} : { 'background-color': '#ffffff !important' }"
                                                            ><input
                                                                :disabled="getRole === 'doctor' || getRole === 'cra' || isModerator"
                                                                v-model="medical_history.eye_pressure"
                                                                v-bind:value="false"
                                                                name="eye_pressure"
                                                                type="radio"
                                                                class="form-check-input"
                                                            />
                                                                Hayır
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-2 float-left">
                                                        <b-button v-if="buttonVisible"
                                                                style="background-color: white; border: 0 !important;"
                                                                v-on:click="showComments('medical_history.eye_pressure')">
                                                            <b-icon icon="question-circle-fill"
                                                                    style="width: 20px; height: 20px;"
                                                                    :variant="status['medical_history.eye_pressure'] ?  (status['medical_history.eye_pressure'].role === 'cra' ? 'danger':'success') : 'primary'"
                                                            >
                                                            </b-icon>
                                                        </b-button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row form-group">
                                            <div class="col-md-10">
                                                <input
                                                    v-model="medical_history.pressure_description"
                                                    :readonly="getRole === 'doctor' || getRole === 'cra' || isModerator"
                                                    name="pressure_description"
                                                    id="pressure_description"
                                                    v-if="medical_history.eye_pressure"
                                                    placeholder="Lütfen gönüllünün tansiyon hastalığı hakkında bilgi veriniz."
                                                    type="text"
                                                    class="form-control"
                                                    :style="buttonVisible ? {'background-color': (status['medical_history.pressure_description'] ?  (status['medical_history.pressure_description'].role === 'cra' ? '#DBA29B' : '#3ac47d') : '#ffffff')} : { 'background-color': '#ffffff !important' }"

                                                />
                                            </div>
                                            <div class="col-sm-2 float-left"
                                                v-if="medical_history.eye_pressure"
                                            >
                                                <b-button v-if="buttonVisible"
                                                        style="background-color: white; border: 0 !important;"
                                                        v-on:click="showComments('medical_history.pressure_description')">
                                                    <b-icon icon="question-circle-fill"
                                                            style="width: 20px; height: 20px;"
                                                            :variant="status['medical_history.pressure_description'] ?  (status['medical_history.pressure_description'].role === 'cra' ? 'danger':'success') : 'primary'"
                                                    ></b-icon>
                                                </b-button>
                                            </div>
                                        </div>
                                        <!-- Question 3 -->
                                        <div class="position-relative row">
                                            <div class="col-sm-12 form-group">
                                                <div class="row form-group">
                                                    <label
                                                        for="is_glaucoma"
                                                        class="col-sm-6 col-form-label"
                                                    >Ailede Glokom (Göz Tansiyonu) hastalığı öyküsü var mı? *
                                                    </label>
                                                    <div class="col-md-1">
                                                        <div class="position-relative form-check">
                                                            <label class="form-check-label"
                                                                   :style="buttonVisible ? {'background-color': (status['medical_history.is_glaucoma'] ?  (status['demographics.is_glaucoma'].role === 'cra' ? '#DBA29B' : '#3ac47d') : '#ffffff')} : { 'background-color': '#ffffff !important' }"
                                                            ><input
                                                                :disabled="getRole === 'doctor' || getRole === 'cra' || isModerator"
                                                                v-model="medical_history.is_glaucoma"
                                                                v-bind:value="true"
                                                                name="is_glaucoma"
                                                                type="radio"
                                                                class="form-check-input"
                                                            />
                                                                Evet
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-1">
                                                        <div class="position-relative form-check">
                                                            <label class="form-check-label"
                                                                   :style="buttonVisible ? {'background-color': (status['medical_history.is_glaucoma'] ?  (status['demographics.is_glaucoma'].role === 'cra' ? '#DBA29B' : '#3ac47d') : '#ffffff')} : { 'background-color': '#ffffff !important' }"
                                                            ><input
                                                                :disabled="getRole === 'doctor' || getRole === 'cra' || isModerator"
                                                                v-model="medical_history.is_glaucoma"
                                                                v-bind:value="false"
                                                                name="is_glaucoma"
                                                                type="radio"
                                                                class="form-check-input"
                                                            />
                                                                Hayır
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-2 float-left">
                                                        <b-button v-if="buttonVisible"
                                                                style="background-color: white; border: 0 !important;"
                                                                v-on:click="showComments('medical_history.is_glaucoma')">
                                                            <b-icon icon="question-circle-fill"
                                                                    style="width: 20px; height: 20px;"
                                                                    :variant="status['medical_history.is_glaucoma'] ?  (status['medical_history.is_glaucoma'].role === 'cra' ? 'danger':'success') : 'primary'"
                                                            >
                                                            </b-icon>
                                                        </b-button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row form-group">
                                            <div class="col-md-10">
                                                <input
                                                    v-model="medical_history.glaucoma_description"
                                                    :readonly="getRole === 'doctor' || getRole === 'cra' || isModerator"
                                                    name="glaucoma_description"
                                                    id="glaucoma_description"
                                                    v-if="medical_history.is_glaucoma"
                                                    placeholder="Lütfen gönüllünün göz tansiyonu hakkında bilgi veriniz."
                                                    type="text"
                                                    class="form-control"
                                                    :style="buttonVisible ? {'background-color': (status['medical_history.glaucoma_description'] ?  (status['medical_history.glaucoma_description'].role === 'cra' ? '#DBA29B' : '#3ac47d') : '#ffffff')} : { 'background-color': '#ffffff !important' }"

                                                />
                                            </div>
                                            <div class="col-sm-2 float-left"
                                                v-if="medical_history.is_glaucoma"
                                            >
                                                <b-button v-if="buttonVisible"
                                                        style="background-color: white; border: 0 !important;"
                                                        v-on:click="showComments('medical_history.glaucoma_description')">
                                                    <b-icon icon="question-circle-fill"
                                                            style="width: 20px; height: 20px;"
                                                            :variant="status['medical_history.glaucoma_description'] ?  (status['medical_history.glaucoma_description'].role === 'cra' ? 'danger':'success') : 'primary'"
                                                    ></b-icon>
                                                </b-button>
                                            </div>
                                        </div>
                                        <!-- Question 4 -->
                                        <div class="position-relative row">
                                            <div class="col-sm-12 form-group">
                                                <div class="row form-group">
                                                    <label
                                                        for="has_family_amd"
                                                        class="col-sm-6 col-form-label"
                                                    >Ailede Yaşa Bağlı Makula Dejenerasyonu hastalığı öyküsü var mı? *
                                                    </label>
                                                    <div class="col-md-1">
                                                        <div class="position-relative form-check">
                                                            <label class="form-check-label"
                                                                   :style="buttonVisible ? {'background-color': (status['medical_history.has_family_amd'] ?  (status['demographics.has_family_amd'].role === 'cra' ? '#DBA29B' : '#3ac47d') : '#ffffff')} : { 'background-color': '#ffffff !important' }"
                                                            ><input
                                                                :disabled="getRole === 'doctor' || getRole === 'cra' || isModerator"
                                                                v-model="medical_history.has_family_amd"
                                                                v-bind:value="true"
                                                                name="has_family_amd"
                                                                type="radio"
                                                                class="form-check-input"
                                                            />
                                                                Evet
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-1">
                                                        <div class="position-relative form-check">
                                                            <label class="form-check-label"
                                                                   :style="buttonVisible ? {'background-color': (status['medical_history.has_family_amd'] ?  (status['demographics.has_family_amd'].role === 'cra' ? '#DBA29B' : '#3ac47d') : '#ffffff')} : { 'background-color': '#ffffff !important' }"
                                                            ><input
                                                                :disabled="getRole === 'doctor' || getRole === 'cra' || isModerator"
                                                                v-model="medical_history.has_family_amd"
                                                                v-bind:value="false"
                                                                name="has_family_amd"
                                                                type="radio"
                                                                class="form-check-input"
                                                            />
                                                                Hayır
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-2 float-left">
                                                        <b-button v-if="buttonVisible"
                                                                style="background-color: white; border: 0 !important;"
                                                                v-on:click="showComments('medical_history.has_family_amd')">
                                                            <b-icon icon="question-circle-fill"
                                                                    style="width: 20px; height: 20px;"
                                                                    :variant="status['medical_history.has_family_amd'] ?  (status['medical_history.has_family_amd'].role === 'cra' ? 'danger':'success') : 'primary'"
                                                            >
                                                            </b-icon>
                                                        </b-button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row form-group">
                                            <div class="col-md-10">
                                                <input
                                                    v-model="medical_history.amd_description"
                                                    :readonly="getRole === 'doctor' || getRole === 'cra' || isModerator"
                                                    name="amd_description"
                                                    id="amd_description"
                                                    v-if="medical_history.has_family_amd"
                                                    placeholder="Lütfen gönüllünün göz tansiyonu hakkında bilgi veriniz."
                                                    type="text"
                                                    class="form-control"
                                                    :style="buttonVisible ? {'background-color': (status['medical_history.amd_description'] ?  (status['medical_history.amd_description'].role === 'cra' ? '#DBA29B' : '#3ac47d') : '#ffffff')} : { 'background-color': '#ffffff !important' }"

                                                />
                                            </div>
                                            <div class="col-sm-2 float-left"
                                                v-if="medical_history.has_family_amd"
                                            >
                                                <b-button v-if="buttonVisible"
                                                        style="background-color: white; border: 0 !important;"
                                                        v-on:click="showComments('medical_history.amd_description')">
                                                    <b-icon icon="question-circle-fill"
                                                            style="width: 20px; height: 20px;"
                                                            :variant="status['medical_history.amd_description'] ?  (status['medical_history.amd_description'].role === 'cra' ? 'danger':'success') : 'primary'"
                                                    ></b-icon>
                                                </b-button>
                                            </div>
                                        </div>
                                        <!-- Question 5 -->
                                        <div class="position-relative row">
                                            <div class="col-sm-12 form-group">
                                                <div class="row form-group">
                                                    <label
                                                        for="other_diseases"
                                                        class="col-sm-6 col-form-label"
                                                    >Tanısı konulmuş miyop(uzağı görememe), hipermetrop(yakını görememe) vb. başka bir göz hastalığı var mı? *
                                                    </label>
                                                    <div class="col-md-1">
                                                        <div class="position-relative form-check">
                                                            <label class="form-check-label"
                                                                   :style="buttonVisible ? {'background-color': (status['medical_history.other_diseases'] ?  (status['demographics.other_diseases'].role === 'cra' ? '#DBA29B' : '#3ac47d') : '#ffffff')} : { 'background-color': '#ffffff !important' }"
                                                            ><input
                                                                :disabled="getRole === 'doctor' || getRole === 'cra' || isModerator"
                                                                v-model="medical_history.other_diseases"
                                                                v-bind:value="true"
                                                                name="other_diseases"
                                                                type="radio"
                                                                class="form-check-input"
                                                            />
                                                                Evet
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-1">
                                                        <div class="position-relative form-check">
                                                            <label class="form-check-label"
                                                                   :style="buttonVisible ? {'background-color': (status['medical_history.other_diseases'] ?  (status['demographics.other_diseases'].role === 'cra' ? '#DBA29B' : '#3ac47d') : '#ffffff')} : { 'background-color': '#ffffff !important' }"
                                                            ><input
                                                                :disabled="getRole === 'doctor' || getRole === 'cra' || isModerator"
                                                                v-model="medical_history.other_diseases"
                                                                v-bind:value="false"
                                                                name="other_diseases"
                                                                type="radio"
                                                                class="form-check-input"
                                                            />
                                                                Hayır
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-2 float-left">
                                                        <b-button v-if="buttonVisible"
                                                                style="background-color: white; border: 0 !important;"
                                                                v-on:click="showComments('medical_history.other_diseases')">
                                                            <b-icon icon="question-circle-fill"
                                                                    style="width: 20px; height: 20px;"
                                                                    :variant="status['medical_history.other_diseases'] ?  (status['medical_history.other_diseases'].role === 'cra' ? 'danger':'success') : 'primary'"
                                                            >
                                                            </b-icon>
                                                        </b-button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row form-group">
                                            <div class="col-md-10">
                                                <input
                                                    v-model="medical_history.other_diseases_description"
                                                    :readonly="getRole === 'doctor' || getRole === 'cra' || isModerator"
                                                    name="other_diseases_description"
                                                    id="other_diseases_description"
                                                    v-if="medical_history.other_diseases"
                                                    placeholder="Lütfen gönüllünün diğer hastalıkları hakkında bilgi veriniz."
                                                    type="text"
                                                    class="form-control"
                                                    :style="buttonVisible ? {'background-color': (status['medical_history.other_diseases_description'] ?  (status['medical_history.other_diseases_description'].role === 'cra' ? '#DBA29B' : '#3ac47d') : '#ffffff')} : { 'background-color': '#ffffff !important' }"

                                                />
                                            </div>
                                            <div class="col-sm-2 float-left"
                                                v-if="medical_history.other_diseases"
                                            >
                                                <b-button v-if="buttonVisible"
                                                        style="background-color: white; border: 0 !important;"
                                                        v-on:click="showComments('medical_history.other_diseases_description')">
                                                    <b-icon icon="question-circle-fill"
                                                            style="width: 20px; height: 20px;"
                                                            :variant="status['medical_history.other_diseases_description'] ?  (status['medical_history.other_diseases_description'].role === 'cra' ? 'danger':'success') : 'primary'"
                                                    ></b-icon>
                                                </b-button>
                                            </div>
                                        </div>
                                        <!-- Question 6 -->
                                        <div class="position-relative row">
                                            <div class="col-sm-12 form-group">
                                                <div class="row form-group">
                                                    <label for="pills" class="col-sm-6 col-form-label"
                                                    >Gönüllünün kullanacağı ya da kullandığı tüm ilaçlar? *
                                                    </label>
                                                    <div class="col-sm-4">
                                                        <input
                                                            :readonly="getRole === 'doctor' || getRole === 'cra' || isModerator"
                                                            v-model="medical_history.pills"
                                                            name="pills"
                                                            id="pills"
                                                            placeholder="Lütfen ilaçları virgül ile ayırınız."
                                                            type="text"
                                                            class="form-control"
                                                            :style="buttonVisible ? {'background-color': (status['medical_history.pills'] ?  (status['medical_history.pills'].role === 'cra' ? '#DBA29B' : '#3ac47d') : '#ffffff')} : { 'background-color': '#ffffff !important' }"

                                                        />
                                                    </div>
                                                    <div class="col-sm-2 float-left">
                                                        <b-button v-if="buttonVisible"
                                                                style="background-color: white; border: 0 !important;"
                                                                v-on:click="showComments('demographics.pills')">
                                                            <b-icon icon="question-circle-fill"
                                                                    style="width: 20px; height: 20px;"
                                                                    :variant="status['medical_history.pills'] ?  (status['medical_history.pills'].role === 'cra' ? 'danger':'success') : 'primary'"
                                                            ></b-icon>
                                                        </b-button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="float-right mb-5 mr-2">
                                            <b-button
                                                size="md"
                                                variant="primary"
                                                v-on:click="medicalHistoryAll"
                                                class="btn btn-wide btn-primary mr-3"
                                                v-if="getRole === 'coordinator' && (this.demographics.status !== 'signed_by_cra') && (this.demographics.status !== 'diagnosed')"
                                            >
                                                <span> Hepsini İşaretle</span>
                                            </b-button>
                                            <b-button
                                                :disabled="$v.medical_history.$invalid"
                                                size="md"
                                                variant="primary"
                                                v-on:click="upsertMedicalHistory"
                                                class="btn btn-wide btn-primary mr-3"
                                                v-if="getRole === 'coordinator' && (this.demographics.status !== 'signed_by_cra') && (this.demographics.status !== 'diagnosed')"
                                            >
                                                <span v-show="!loading"> Kaydet & Devam  </span>
                                                <div v-show="loading" class="spinner-border spinner-border-sm"
                                                     role="status">
                                                    <span class="sr-only">Yükleniyor...</span>
                                                </div>
                                            </b-button>
                                        </div>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>

                            <!-- INCLUSION CRITERIA -->
                            <b-card v-if="!$v.medical_history.$invalid" v-show="getRole === 'coordinator' || getRole === 'cra' || getRole === 'doctor'"
                                    no-body class="mb-1">
                                <b-card-header
                                    :style="getRole === 'coordinator' || getRole === 'cra' ? Object.keys(this.status).map((el) => el.split('.')[0]).includes('inclusion') ? {'background-color': '#DBA29B'} : { 'background-color': '#ffffff !important' } : ''"
                                    header-tag="header"
                                    class="p-1"
                                    role="tab"
                                    @click="jumpToSection(3)"
                                >
                                    <div class="p-3 d-block">
                                        <h5 class="card-title font-weight-bold d-inline">
                                            DAHİL EDİLME KRİTERLERİ
                                        </h5>
                                    </div>
                                </b-card-header>
                                <b-collapse
                                    id="accordion3"
                                    :visible="step === 2"
                                    accordion="my-accordion"
                                    role="tabpanel"
                                >
                                    <b-card-body>
                                        <!-- Question 1 : Inclusion -->
                                        <div class="position-relative row">
                                            <div class="col-sm-12 form-group">
                                                <div class="row form-group">
                                                    <div class="col-md-8">
                                                        <label class="col-form-label form-check-label"
                                                        >Potansiyel katılımcı çalışmayı anladı ve bilgilendirilmiş onam formunu imzaladı *
                                                        </label>
                                                    </div>
                                                    <div class="col-md-1">
                                                        <div class="position-relative form-check">
                                                            <label class="form-check-label"
                                                                   :style="buttonVisible ? {'color': (status['inclusion.inclusion1'] ?  (status['inclusion.inclusion1'].role === 'cra' ? '#DBA29B' : '#3ac47d') : '#333333')} : { 'color': '#333333 !important' }"
                                                            ><input
                                                                :disabled="getRole === 'doctor' || getRole === 'cra' || isModerator"
                                                                v-model="inclusion.inclusion1"
                                                                v-bind:value="true"
                                                                name="inclusion1"
                                                                type="radio"
                                                                class="form-check-input"
                                                            />
                                                                Evet
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-1">
                                                        <div class="position-relative form-check">
                                                            <label class="form-check-label"
                                                                   :style="buttonVisible ? {'color': (status['inclusion.inclusion1'] ?  (status['inclusion.inclusion1'].role === 'cra' ? '#DBA29B' : '#3ac47d') : '#333333')} : { 'color': '#333333 !important' }"
                                                            ><input
                                                                :disabled="getRole === 'doctor' || getRole === 'cra' || isModerator"
                                                                v-model="inclusion.inclusion1"
                                                                v-bind:value="false"
                                                                name="inclusion1"
                                                                type="radio"
                                                                class="form-check-input"
                                                            />
                                                                Hayır
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-2 float-left">
                                                        <b-button v-if="buttonVisible"
                                                                  style="background-color: white; border: 0 !important;"
                                                                  v-on:click="showComments('inclusion.inclusion1')">
                                                            <b-icon icon="question-circle-fill"
                                                                    style="width: 20px; height: 20px;"
                                                                    :variant="status['inclusion.inclusion1'] ?  (status['inclusion.inclusion1'].role === 'cra' ? 'danger':'success') : 'primary'"
                                                            ></b-icon>
                                                        </b-button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Question 2 : Inclusion -->
                                        <div class="position-relative row">
                                            <div class="col-sm-12 form-group">
                                                <div class="row form-group">
                                                    <div class="col-md-8">
                                                        <label class="col-form-label form-check-label"
                                                        >Retina veya optik diskin görünümünü etkileyebilecek başka herhangi bir retinal vasküler hastalık, katarakt veya başka bir hastalık öyküsü yok (kırma kusuru ve oküler yüzey hastalığına izin verilir) *
                                                        </label>
                                                    </div>
                                                    <div class="col-md-1">
                                                        <div class="position-relative form-check">
                                                            <label class="form-check-label"
                                                                   :style="buttonVisible ? {'color': (status['inclusion.inclusion2'] ?  (status['inclusion.inclusion2'].role === 'cra' ? '#DBA29B' : '#3ac47d') : '#333333')} : { 'color': '#333333 !important' }"
                                                            ><input
                                                                :disabled="getRole === 'doctor' || getRole === 'cra' || isModerator"
                                                                v-model="inclusion.inclusion2"
                                                                v-bind:value="true"
                                                                name="inclusion2"
                                                                type="radio"
                                                                class="form-check-input"
                                                            />
                                                                Evet
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-1">
                                                        <div class="position-relative form-check">
                                                            <label class="form-check-label"
                                                                   :style="buttonVisible ? {'color': (status['inclusion.inclusion2'] ?  (status['inclusion.inclusion2'].role === 'cra' ? '#DBA29B' : '#3ac47d') : '#333333')} : { 'color': '#333333 !important' }"
                                                            ><input
                                                                :disabled="getRole === 'doctor' || getRole === 'cra' || isModerator"
                                                                v-model="inclusion.inclusion2"
                                                                v-bind:value="false"
                                                                name="inclusion2"
                                                                type="radio"
                                                                class="form-check-input"
                                                            />
                                                                Hayır
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-2 float-left">
                                                        <b-button v-if="buttonVisible"
                                                                  style="background-color: white; border: 0 !important;"
                                                                  v-on:click="showComments('inclusion.inclusion2')">
                                                            <b-icon icon="question-circle-fill"
                                                                    style="width: 20px; height: 20px;"
                                                                    :variant="status['inclusion.inclusion2'] ?  (status['inclusion.inclusion2'].role === 'cra' ? 'danger':'success') : 'primary'"
                                                            ></b-icon>
                                                        </b-button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Question 3 : Inclusion -->
                                        <div class="position-relative row">
                                            <div class="col-sm-12 form-group">
                                                <div class="row form-group">
                                                    <div class="col-md-8">
                                                        <label class="col-form-label form-check-label">
                                                            Katarakt cerrahisi dışında, herhangi bir göz içi cerrahi, herhangi bir retina hastalığı için oküler lazer tedavisi öyküsü yok *
                                                        </label>
                                                    </div>
                                                    <div class="col-md-1">
                                                        <div class="position-relative form-check">
                                                            <label class="form-check-label"
                                                                   :style="buttonVisible ? {'color': (status['inclusion.inclusion3'] ?  (status['inclusion.inclusion3'].role === 'cra' ? '#DBA29B' : '#3ac47d') : '#333333')} : { 'color': '#333333 !important' }"
                                                            ><input
                                                                :disabled="getRole === 'doctor' || getRole === 'cra' || isModerator"
                                                                v-model="inclusion.inclusion3"
                                                                v-bind:value="true"
                                                                name="inclusion3"
                                                                type="radio"
                                                                class="form-check-input"
                                                            />
                                                                Evet
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-1">
                                                        <div class="position-relative form-check">
                                                            <label class="form-check-label"
                                                                   :style="buttonVisible ? {'color': (status['inclusion.inclusion3'] ?  (status['inclusion.inclusion3'].role === 'cra' ? '#DBA29B' : '#3ac47d') : '#333333')} : { 'color': '#333333 !important' }"
                                                            ><input
                                                                :disabled="getRole === 'doctor' || getRole === 'cra' || isModerator"
                                                                v-model="inclusion.inclusion3"
                                                                v-bind:value="false"
                                                                name="inclusion3"
                                                                type="radio"
                                                                class="form-check-input"
                                                            />
                                                                Hayır
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-2 float-left">
                                                        <b-button v-if="buttonVisible"
                                                                  style="background-color: white; border: 0 !important;"
                                                                  v-on:click="showComments('inclusion.inclusion3')">
                                                            <b-icon icon="question-circle-fill"
                                                                    style="width: 20px; height: 20px;"
                                                                    :variant="status['inclusion.inclusion3'] ?  (status['inclusion.inclusion3'].role === 'cra' ? 'danger':'success') : 'primary'"
                                                            ></b-icon>
                                                        </b-button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Question 4 : Inclusion -->
                                        <div class="position-relative row">
                                            <div class="col-sm-12 form-group">
                                                <div class="row form-group">
                                                    <div class="col-md-8">
                                                        <label class="col-form-label form-check-label"
                                                        >Potansiyel katılımcı 18 yaş ve üstü *
                                                        </label>
                                                    </div>
                                                    <div class="col-md-1">
                                                        <div class="position-relative form-check">
                                                            <label class="form-check-label"
                                                                   :style="buttonVisible ? {'color': (status['inclusion.inclusion4'] ?  (status['inclusion.inclusion4'].role === 'cra' ? '#DBA29B' : '#3ac47d') : '#333333')} : { 'color': '#333333 !important' }"
                                                            ><input
                                                                :disabled="getRole === 'doctor' || getRole === 'cra' || isModerator"
                                                                v-model="inclusion.inclusion4"
                                                                v-bind:value="true"
                                                                name="inclusion4"
                                                                type="radio"
                                                                class="form-check-input"
                                                            />
                                                                Evet
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-1">
                                                        <div class="position-relative form-check">
                                                            <label class="form-check-label"
                                                                   :style="buttonVisible ? {'color': (status['inclusion.inclusion4'] ?  (status['inclusion.inclusion4'].role === 'cra' ? '#DBA29B' : '#3ac47d') : '#333333')} : { 'color': '#333333 !important' }"
                                                            ><input
                                                                :disabled="getRole === 'doctor' || getRole === 'cra' || isModerator"
                                                                v-model="inclusion.inclusion4"
                                                                v-bind:value="false"
                                                                name="inclusion4"
                                                                type="radio"
                                                                class="form-check-input"
                                                            />
                                                                Hayır
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-2 float-left">
                                                        <b-button v-if="buttonVisible"
                                                                  style="background-color: white; border: 0 !important;"
                                                                  v-on:click="showComments('inclusion.inclusion4')">
                                                            <b-icon icon="question-circle-fill"
                                                                    style="width: 20px; height: 20px;"
                                                                    :variant="status['inclusion.inclusion4'] ?  (status['inclusion.inclusion4'].role === 'cra' ? 'danger':'success') : 'primary'"
                                                            ></b-icon>
                                                        </b-button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="float-right mb-5 mr-2">
                                            <b-button
                                                size="md"
                                                variant="primary"
                                                v-on:click="inclusionAll"
                                                class="btn btn-wide btn-primary mr-3"
                                                v-if="getRole === 'coordinator' && (this.demographics.status !== 'signed_by_cra') && (this.demographics.status !== 'diagnosed')"
                                            >
                                                <span> Hepsini İşaretle</span>
                                            </b-button>
                                            <b-button
                                                size="md"
                                                :disabled="$v.inclusion.$invalid"
                                                variant="primary"
                                                v-on:click="upsertInclusion"
                                                class="btn btn-wide btn-primary mr-3"
                                                v-if="getRole === 'coordinator' && (this.demographics.status !== 'signed_by_cra') && (this.demographics.status !== 'diagnosed')"
                                            >
                                                <span v-show="!loading"> Kaydet & Devam  </span>
                                                <div v-show="loading" class="spinner-border spinner-border-sm"
                                                     role="status">
                                                    <span class="sr-only">Yükleniyor...</span>
                                                </div>
                                            </b-button>
                                        </div>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>

                            <!-- EXCLUSION CRITERIA -->
                            <b-card v-if="!$v.inclusion.$invalid" v-show="getRole === 'coordinator' || getRole === 'cra' || getRole === 'doctor'"
                                    no-body class="mb-1">
                                <b-card-header
                                    :style="getRole === 'coordinator' || getRole === 'cra' ? Object.keys(this.status).map((el) => el.split('.')[0]).includes('exclusion') ? {'background-color': '#DBA29B'} : { 'background-color': '#ffffff !important' } : ''"
                                    header-tag="header"
                                    class="p-1"
                                    role="tab"
                                    @click="jumpToSection(4)"
                                >
                                    <div class="p-3 d-block">
                                        <h5 class="card-title font-weight-bold d-inline">
                                            HARİÇ TUTMA KRİTERLERİ
                                        </h5>
                                    </div>
                                </b-card-header>
                                <b-collapse
                                    id="accordion4"
                                    :visible="step === 3"
                                    accordion="my-accordion"
                                    role="tabpanel"
                                >
                                    <b-card-body>
                                        <!-- Question 1 : Exclusion -->
                                        <div class="position-relative row">
                                            <div class="col-sm-12 form-group">
                                                <div class="row form-group">
                                                    <div class="col-md-8">
                                                        <label class="col-form-label form-check-label"
                                                        >Potansiyel katılımcı çalışmayı veya bilgilendirilmiş onamı anlamadı *
                                                        </label>
                                                    </div>
                                                    <div class="col-md-1">
                                                        <div class="position-relative form-check">
                                                            <label class="form-check-label"
                                                                   :style="buttonVisible ? {'color': (status['exclusion.exclusion1'] ?  (status['exclusion.exclusion1'].role === 'cra' ? '#DBA29B' : '#3ac47d') : '#333333')} : { 'color': '#333333 !important' }"
                                                            ><input
                                                                :disabled="getRole === 'doctor' || getRole === 'cra' || isModerator"
                                                                v-model="exclusion.exclusion1"
                                                                v-bind:value="true"
                                                                name="exclusion1"
                                                                type="radio"
                                                                class="form-check-input"
                                                            />
                                                                Evet
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-1">
                                                        <div class="position-relative form-check">
                                                            <label class="form-check-label"
                                                                   :style="buttonVisible ? {'color': (status['exclusion.exclusion1'] ?  (status['exclusion.exclusion1'].role === 'cra' ? '#DBA29B' : '#3ac47d') : '#333333')} : { 'color': '#333333 !important' }"
                                                            ><input
                                                                :disabled="getRole === 'doctor' || getRole === 'cra' || isModerator"
                                                                v-model="exclusion.exclusion1"
                                                                v-bind:value="false"
                                                                name="exclusion1"
                                                                type="radio"
                                                                class="form-check-input"
                                                            />
                                                                Hayır
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-2 float-left">
                                                        <b-button v-if="buttonVisible"
                                                                  style="background-color: white; border: 0 !important;"
                                                                  v-on:click="showComments('exclusion.exclusion1')">
                                                            <b-icon icon="question-circle-fill"
                                                                    style="width: 20px; height: 20px;"
                                                                    :variant="status['exclusion.exclusion1'] ?  (status['exclusion.exclusion1'].role === 'cra' ? 'danger':'success') : 'primary'"
                                                            ></b-icon>
                                                        </b-button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Question 2 : Exclusion -->
                                        <div class="position-relative row">
                                            <div class="col-sm-12 form-group">
                                                <div class="row form-group">
                                                    <div class="col-md-8">
                                                        <label class="col-form-label form-check-label"
                                                        >Değerlendirilecek özellikte fundus fotoğrafı alınmasını engelleyecek medya opasitesi var *</label
                                                        >
                                                    </div>
                                                    <div class="col-md-1">
                                                        <div class="position-relative form-check">
                                                            <label class="form-check-label"
                                                                   :style="buttonVisible ? {'color': (status['exclusion.exclusion2'] ?  (status['exclusion.exclusion2'].role === 'cra' ? '#DBA29B' : '#3ac47d') : '#333333')} : { 'color': '#333333 !important' }"
                                                            ><input
                                                                :disabled="getRole === 'doctor' || getRole === 'cra' || isModerator"
                                                                v-model="exclusion.exclusion2"
                                                                v-bind:value="true"
                                                                name="exclusion2"
                                                                type="radio"
                                                                class="form-check-input"
                                                            />
                                                                Evet
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-1">
                                                        <div class="position-relative form-check">
                                                            <label class="form-check-label"
                                                                   :style="buttonVisible ? {'color': (status['exclusion.exclusion2'] ?  (status['exclusion.exclusion2'].role === 'cra' ? '#DBA29B' : '#3ac47d') : '#333333')} : { 'color': '#333333 !important' }"
                                                            ><input
                                                                :disabled="getRole === 'doctor' || getRole === 'cra' || isModerator"
                                                                v-model="exclusion.exclusion2"
                                                                v-bind:value="false"
                                                                name="exclusion2"
                                                                type="radio"
                                                                class="form-check-input"
                                                            />
                                                                Hayır
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-2 float-left">
                                                        <b-button v-if="buttonVisible"
                                                                  style="background-color: white; border: 0 !important;"
                                                                  v-on:click="showComments('exclusion.exclusion2')">
                                                            <b-icon icon="question-circle-fill"
                                                                    style="width: 20px; height: 20px;"
                                                                    :variant="status['exclusion.exclusion2'] ?  (status['exclusion.exclusion2'].role === 'cra' ? 'danger':'success') : 'primary'"
                                                            ></b-icon>
                                                        </b-button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Question 3 : Exclusion -->
                                        <div class="position-relative row">
                                            <div class="col-sm-12 form-group">
                                                <div class="row form-group">
                                                    <div class="col-md-8">
                                                        <label class="col-form-label form-check-label">
                                                            Katarakt dışında önceki göz içi ameliyatı geçirmiş, retinaya lazer müdahalesi var *
                                                        </label>
                                                    </div>
                                                    <div class="col-md-1">
                                                        <div class="position-relative form-check">
                                                            <label class="form-check-label"
                                                                   :style="buttonVisible ? {'color': (status['exclusion.exclusion3'] ?  (status['exclusion.exclusion3'].role === 'cra' ? '#DBA29B' : '#3ac47d') : '#333333')} : { 'color': '#333333 !important' }"
                                                            ><input
                                                                :disabled="getRole === 'doctor' || getRole === 'cra' || isModerator"
                                                                v-model="exclusion.exclusion3"
                                                                v-bind:value="true"
                                                                name="exclusion3"
                                                                type="radio"
                                                                class="form-check-input"
                                                            />
                                                                Evet
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-1">
                                                        <div class="position-relative form-check">
                                                            <label class="form-check-label"
                                                                   :style="buttonVisible ? {'color': (status['exclusion.exclusion3'] ?  (status['exclusion.exclusion3'].role === 'cra' ? '#DBA29B' : '#3ac47d') : '#333333')} : { 'color': '#333333 !important' }"
                                                            ><input
                                                                :disabled="getRole === 'doctor' || getRole === 'cra' || isModerator"
                                                                v-model="exclusion.exclusion3"
                                                                v-bind:value="false"
                                                                name="exclusion3"
                                                                type="radio"
                                                                class="form-check-input"
                                                            />
                                                                Hayır
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-2 float-left">
                                                        <b-button v-if="buttonVisible"
                                                                  style="background-color: white; border: 0 !important;"
                                                                  v-on:click="showComments('exclusion.exclusion3')">
                                                            <b-icon icon="question-circle-fill"
                                                                    style="width: 20px; height: 20px;"
                                                                    :variant="status['exclusion.exclusion3'] ?  (status['exclusion.exclusion3'].role === 'cra' ? 'danger':'success') : 'primary'"
                                                            ></b-icon>
                                                        </b-button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Question 4 : Exclusion -->
                                        <div class="position-relative row">
                                            <div class="col-sm-12 form-group">
                                                <div class="row form-group">
                                                    <div class="col-md-8">
                                                        <label class="col-form-label form-check-label"
                                                        >Potansiyel katılımcı, çalışmada kullanılan fundus görüntüleme sistemleri ile görüntüleme için kontrendikedir *
                                                        </label>
                                                    </div>
                                                    <div class="col-md-1">
                                                        <div class="position-relative form-check">
                                                            <label class="form-check-label"
                                                                   :style="buttonVisible ? {'color': (status['exclusion.exclusion4'] ?  (status['exclusion.exclusion4'].role === 'cra' ? '#DBA29B' : '#3ac47d') : '#333333')} : { 'color': '#333333 !important' }"
                                                            ><input
                                                                :disabled="getRole === 'doctor' || getRole === 'cra' || isModerator"
                                                                v-model="exclusion.exclusion4"
                                                                v-bind:value="true"
                                                                name="exclusion4"
                                                                type="radio"
                                                                class="form-check-input"
                                                            />
                                                                Evet
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-1">
                                                        <div class="position-relative form-check">
                                                            <label class="form-check-label"
                                                                   :style="buttonVisible ? {'color': (status['exclusion.exclusion4'] ?  (status['exclusion.exclusion4'].role === 'cra' ? '#DBA29B' : '#3ac47d') : '#333333')} : { 'color': '#333333 !important' }"
                                                            ><input
                                                                :disabled="getRole === 'doctor' || getRole === 'cra' || isModerator"
                                                                v-model="exclusion.exclusion4"
                                                                v-bind:value="false"
                                                                name="exclusion4"
                                                                type="radio"
                                                                class="form-check-input"
                                                            />
                                                                Hayır
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-2 float-left">
                                                        <b-button v-if="buttonVisible"
                                                                  style="background-color: white; border: 0 !important;"
                                                                  v-on:click="showComments('exclusion.exclusion4')">
                                                            <b-icon icon="question-circle-fill"
                                                                    style="width: 20px; height: 20px;"
                                                                    :variant="status['exclusion.exclusion4'] ?  (status['exclusion.exclusion4'].role === 'cra' ? 'danger':'success') : 'primary'"
                                                            ></b-icon>
                                                        </b-button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Question 5 : Exclusion -->
                                        <div class="position-relative row">
                                            <div class="col-sm-12 form-group">
                                                <div class="row form-group">
                                                    <div class="col-md-8">
                                                        <label class="col-form-label form-check-label"
                                                        >Potansiyel katılımcı 18 yaş altı *
                                                        </label>
                                                    </div>
                                                    <div class="col-md-1">
                                                        <div class="position-relative form-check">
                                                            <label class="form-check-label"
                                                                   :style="buttonVisible ? {'color': (status['exclusion.exclusion5'] ?  (status['exclusion.exclusion5'].role === 'cra' ? '#DBA29B' : '#3ac47d') : '#333333')} : { 'color': '#333333 !important' }"
                                                            ><input
                                                                :disabled="getRole === 'doctor' || getRole === 'cra' || isModerator"
                                                                v-model="exclusion.exclusion5"
                                                                v-bind:value="true"
                                                                name="exclusion5"
                                                                type="radio"
                                                                class="form-check-input"
                                                            />
                                                                Evet
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-1">
                                                        <div class="position-relative form-check">
                                                            <label class="form-check-label"
                                                                   :style="buttonVisible ? {'color': (status['exclusion.exclusion5'] ?  (status['exclusion.exclusion5'].role === 'cra' ? '#DBA29B' : '#3ac47d') : '#333333')} : { 'color': '#333333 !important' }"
                                                            ><input
                                                                :disabled="getRole === 'doctor' || getRole === 'cra' || isModerator"
                                                                v-model="exclusion.exclusion5"
                                                                v-bind:value="false"
                                                                name="exclusion5"
                                                                type="radio"
                                                                class="form-check-input"
                                                            />
                                                                Hayır
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-2 float-left">
                                                        <b-button v-if="buttonVisible"
                                                                  style="background-color: white; border: 0 !important;"
                                                                  v-on:click="showComments('exclusion.exclusion5')">
                                                            <b-icon icon="question-circle-fill"
                                                                    style="width: 20px; height: 20px;"
                                                                    :variant="status['exclusion.exclusion5'] ?  (status['exclusion.exclusion5'].role === 'cra' ? 'danger':'success') : 'primary'"
                                                            ></b-icon>
                                                        </b-button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="float-right mb-5 mr-2">
                                            <b-button
                                                size="md"
                                                variant="primary"
                                                v-on:click="exclusionAll"
                                                class="btn btn-wide btn-primary mr-3"
                                                v-if="getRole === 'coordinator' && (this.demographics.status !== 'signed_by_cra') && (this.demographics.status !== 'diagnosed')"
                                            >
                                                <span> Hepsini İşaretle</span>
                                            </b-button>
                                            <b-button
                                                size="md"
                                                :disabled="$v.exclusion.$invalid"
                                                variant="primary"
                                                v-on:click="upsertExclusion"
                                                class="btn btn-wide btn-primary mr-3"
                                                v-if="getRole === 'coordinator' && (this.demographics.status !== 'signed_by_cra') && (this.demographics.status !== 'diagnosed')"
                                            >
                                                <span v-show="!loading"> Kaydet & Devam  </span>
                                                <div v-show="loading" class="spinner-border spinner-border-sm"
                                                     role="status">
                                                    <span class="sr-only">Yükleniyor...</span>
                                                </div>
                                            </b-button>
                                        </div>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>

                            <!-- EYECHECKUP DIAGNOSIS -->
                            <b-card v-if="!$v.exclusion.$invalid" v-show="getRole === 'coordinator' || getRole === 'cra'" no-body class="mb-1">
                                <b-card-header
                                    :style="getRole === 'coordinator' || getRole === 'cra' ? Object.keys(this.status).map((el) => el.split('.')[0]).includes('diagnosis') ? {'background-color': '#DBA29B'} : { 'background-color': '#ffffff !important' } : ''"
                                    header-tag="header"
                                    class="p-1"
                                    role="tab"
                                    @click="jumpToSection(5)"
                                >
                                    <div class="p-3 d-block">
                                        <h5 class="card-title font-weight-bold d-inline">
                                            EYECHECKUP TEŞHİSLERİ
                                        </h5>
                                        <b class="ml-5" v-if="demographics.single_eye">(Tek Göz)</b>
                                    </div>
                                </b-card-header>
                                <b-collapse
                                    id="accordion5"
                                    :visible="step === 4"
                                    accordion="my-accordion"
                                    role="tabpanel"
                                >
                                    <b-card-body>
                                        <b-row class="justify-content-md-center">
                                            <b-col md="10" sm="12">
                                                <b-table
                                                    :items="diagnosisItems"
                                                    :fields="diagnosisFields"
                                                    responsive="sm"
                                                    striped
                                                    hover
                                                    bordered
                                                >
                                                    <template #thead-top>
                                                        <b-tr>
                                                            <b-th colspan="1"><span class="sr-only">Diagnosis Name</span></b-th>
                                                            <b-th variant="danger" colspan="3">LEFT EYE</b-th>
                                                            <b-th variant="primary" colspan="3">RIGHT EYE</b-th>
                                                        </b-tr>
                                                    </template>
                                                </b-table>
                                            </b-col>
                                        </b-row>

                                        <div class="float-right mb-5 mr-2">
                                            <b-button
                                                size="md"
                                                variant="primary"
                                                class="btn btn-wide btn-primary mr-3"
                                                v-on:click="upsertEyeCheckup"
                                                
                                            >
                                                <span v-show="!loading"> Kaydet & Devam  </span>
                                                <div v-show="loading" class="spinner-border spinner-border-sm"
                                                     role="status">
                                                    <span class="sr-only">Yükleniyor...</span>
                                                </div>
                                            </b-button>
                                        </div>
                                          <div class="float-right mb-5 mr-2">
                                            <b-button
                                                size="md"
                                                variant="primary"
                                                class="btn btn-wide btn-primary mr-3"
                                                v-on:click="getDiagnosesFromEyeCheckup"
                                                :disabled="getDiagnosesButtonDisabled"
                                            >
                                                <span> Eyecheckup'tan Getir </span>
                                            </b-button>
                                        </div>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>

                            <!-- UPLOAD PHOTOS -->
                            <b-card v-if="!$v.diagnosis.$invalid" v-show="(getRole === 'coordinator' || getRole === 'cra')" no-body class="mb-1">
                                <b-card-header
                                    :style="getRole === 'coordinator' || getRole === 'cra' ? Object.keys(this.status).map((el) => el.split('.')[0]).includes('uploads') ? {'background-color': '#DBA29B'} : { 'background-color': '#ffffff !important' } : ''"
                                    header-tag="header"
                                    class="p-1"
                                    role="tab"
                                    @click="jumpToSection(6)"
                                >
                                    <div class="p-3 d-block">
                                        <h5 class="card-title font-weight-bold d-inline">FOTOĞRAF YÜKLE</h5>
                                        <b class="ml-5" v-if="demographics.single_eye">(Tek Göz)</b>
                                    </div>
                                </b-card-header>
                                <b-collapse
                                    id="accordion6"
                                    :visible="step === 5"
                                    accordion="my-accordion"
                                    role="tabpanel"
                                >
                                    <b-card-body>
                                        <b-row v-show="(getRole === 'coordinator' || getRole === 'cra')">
                                            <b-col md="6" sm="12">
                                                <div class="col-sm-12 float-left">
                                                    
                                                </div>
                                                <div class="float-div-right">
                                                    <b-button v-if="buttonVisible"
                                                              style="background-color: white; border: 0 !important;"
                                                              v-on:click="showComments('uploads.left')">
                                                        <b-icon icon="question-circle-fill"
                                                                style="width: 20px; height: 20px;"
                                                                :variant="status['uploads.left'] ?  (status['uploads.left'].role === 'cra' ? 'danger':'success') : 'primary'"
                                                        ></b-icon>
                                                    </b-button>
                                                    <div :class="getRole === 'coordinator' ? 'hide-delete' : ''">
                                                        <p class="font-weight-bold">Sol Göz Fotoğrafları:</p>
                                                        <div v-for="upload in uploads.left" v-bind:key="upload.id">
                                                            <b-img thumbnail fluid v-img:group4
                                                                   v-bind:src="upload.path"/>
                                                        </div>
                                                    </div>

                                                    <div :class="getRole === 'cra' ? 'hide-delete' : ''" id="leftUploadSection">
                                                        <h5 class="text-center">Sol Göz Fotoğrafı Yükle:</h5>
                                                        <vue-upload-multiple-image
                                                            :disabled="true"
                                                            id-upload="left-eye"
                                                            @upload-success="uploadLeftEye"
                                                            :data-images.sync="uploads.left"
                                                            :maxImage=12
                                                            :showPrimary="false"
                                                            :showEdit="false"
                                                            dragText="Resim Eklemek için"
                                                            markIsPrimaryText="Upload Photos"
                                                            primary-text="Left Eyes"
                                                            browseText="EyeCheckup'tan Getir Butonunu Kullanınız!"
                                                            drop-text="EyeCheckup'tan Getir Butonunu Kullanınız!"
                                                            popupText=""
                                                        >
                                                        </vue-upload-multiple-image>
                                                    </div>
                                                </div>
                                            </b-col>
                                            <b-col md="6" sm="12">
                                                <div class="float-div-left">
                                                    <b-button v-if="buttonVisible"
                                                              style="background-color: white; border: 0 !important;"
                                                              v-on:click="showComments('uploads.right')">
                                                        <b-icon icon="question-circle-fill"
                                                                style="width: 20px; height: 20px;"
                                                                :variant="status['uploads.right'] ?  (status['uploads.right'].role === 'cra' ? 'danger':'success') : 'primary'"
                                                        ></b-icon>
                                                    </b-button>
                                                    <div :class="getRole === 'coordinator' ? 'hide-delete' : ''">
                                                        <p class="font-weight-bold">Sağ Göz Fotoğrafları:</p>
                                                        <div v-for="upload in uploads.right" v-bind:key="upload.id">
                                                            <b-img height="100px" thumbnail fluid v-img:group3
                                                                   v-bind:src="upload.path"/>
                                                        </div>
                                                    </div>
                                                    <div :class="getRole === 'cra' ? 'hide-delete' : ''" id="rightUploadSection">
                                                        <h5 class="text-center">Sağ Göz Fotoğrafı Yükle:</h5>
                                                        <vue-upload-multiple-image
                                                            :disabled="true"
                                                            id-upload="right-eye"
                                                            @upload-success="uploadRightEye"
                                                            :data-images.sync="uploads.right"
                                                            :maxImage=12
                                                            :showPrimary="false"
                                                            :showEdit="false"
                                                            dragText="Resim Eklemek için"
                                                            markIsPrimaryText="Uploaded Photo"
                                                            primary-text="Uploaded Photo"
                                                            browseText="EyeCheckup'tan Getir Butonunu Kullanınız!"
                                                            drop-text="EyeCheckup'tan Getir Butonunu Kullanınız!"
                                                            popupText=""
                                                        ></vue-upload-multiple-image>

                                                    </div>
                                                </div>
                                            </b-col>
                                        </b-row>
                                        <div class="float-right mb-5 mr-2">
                                            <b-button
                                                size="md"
                                                variant="primary"
                                                v-b-toggle="'accordion7'"
                                                class="btn btn-wide btn-primary mr-3"
                                                :disabled="demographics.single_eye ? !(uploads.left.length != 0 || uploads.right.length != 0) : !(uploads.left.length != 0 && uploads.right.length != 0)"
                                                v-on:click="upsertUploads()"
                                                v-if="getRole === 'coordinator' && (this.demographics.status !== 'signed_by_cra')"
                                            >
                                            <!-- && (this.demographics.status !== 'diagnosed') -->
                                                <span v-show="!loading"> Kaydet & Devam  </span>
                                                <div v-show="loading" class="spinner-border spinner-border-sm"
                                                     role="status">
                                                    <span class="sr-only">Yükleniyor...</span>
                                                </div>
                                            </b-button>
                                        </div>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>

                            <!-- REVIEW SECTION FOR DOCTOR -->
                            <b-card v-show="(getRole === 'doctor' && !isSuper === true)" no-body class="mb-1">
                                <b-card-header
                                    header-tag="header"
                                    class="p-1"
                                    role="tab"
                                    @click="jumpToSection(7)"
                                >
                                    <div class="p-3 d-block">
                                        <h5 class="card-title font-weight-bold d-inline">Teşhisler</h5>
                                        <b class="ml-5" v-if="demographics.single_eye">(Tek Göz)</b>
                                    </div>
                                </b-card-header>
                                <b-collapse
                                    id="accordion7"
                                    :visible="step === 6"
                                    accordion="my-accordion"
                                    role="tabpanel"
                                >
                                    <b-card-body class="text-center">
                                        <b-row class="m-5 justify-content-md-center">
                                            <b-modal body-class="d-flex flex-column justify-content-center m-auto" size="xl" id="fundus-modal" title="Göz Fotoğrafları" hide-header hide-footer>
                                                <h1 class="text-center">{{selectedEye == 'right' ? 'SAĞ' : 'SOL'}} GÖZ {{ cupDiscRatio !== 'NaN' ? 'CUP/DISC ORANI: ' + cupDiscRatio : ''}}</h1>
                                                <h3 class="text-center">{{'Lütfen CUP/DISC hesabı için 2 adet çap çizimi yapın.'}}</h3>
                                                <div style="height: 70vh;" class="d-flex flex-column">
                                                    <v-stage
                                                        style="border: 3px solid #000;"
                                                        class="m-auto"
                                                        ref="stageRef" 
                                                        :config="configKonva" 
                                                        @mouseenter="handleMouseEnter"
                                                        @mouseleave="handleMouseLeave" 
                                                        @mousedown="handleMouseDown" 
                                                        @mousemove="handleMouseMove" 
                                                        @mouseup="handleMouseUp" 
                                                        @mousewheel="handleMouseWheel"
                                                    >
                                                        <v-layer :config="{draggable: dragMode}" ref="layerRef">
                                                            <v-image :config="{
                                                                image: canvasImage
                                                            }"/>
                                                            <v-line
                                                                ref="lineRef"
                                                                v-for="(line, index) in Object.keys(lines)"
                                                                :key="index"
                                                                :config="{
                                                                    stroke: 'white',
                                                                    points: [lines[line].startX, lines[line].startY, lines[line].endX, lines[line].endY],
                                                            }"/>
                                                        </v-layer>
                                                    </v-stage>
                                                    <div class="d-flex flex-row">
                                                        <button :disabled="selIndex == 0" class="btn btn-primary m-2" v-on:click="canvasImageSet(uploads[selectedEye][selIndex - 1]?.path); selIndex--; resetImageScaleFromCanvas()">&lt;&lt;</button>
                                                        <button :class="dragMode ? 'btn btn-danger m-2' : 'btn btn-success m-2'" v-on:click="changeMode()">{{dragMode ? 'Çizim Modu: (K)' : 'Çizim Modu: (A)'}}</button>
                                                        <button class="btn btn-warning m-2" v-on:click="resetLines()">Temizle</button>
                                                        <button :disabled="selIndex == uploads[selectedEye].length - 1" class="btn btn-primary m-2" v-on:click="canvasImageSet(uploads[selectedEye][selIndex + 1]?.path); selIndex++; resetImageScaleFromCanvas()">&gt;&gt;</button>
                                                    </div>
                                                </div>
                                            </b-modal>
                                        </b-row>
                                        <h4>Hasta <b>{{demographics.age}}</b> yaşında.</h4>
                                        <br/>
                                        <b-row v-if="uploads.left.length !== 0 || uploads.right.length !== 0" class="m-5 justify-content-md-center">
                                            <b-col v-if="uploads.right.length !== 0" :class="uploads.left.length !== 0 ? 'vertical-hr' : ''" md="6">
                                                <label class="col-form-label font-weight-bold"> SAĞ GÖZ FOTOĞRAFLARI (Toplam: {{uploads.right.length}} görsel)</label>
                                                <b-row class="m-5">
                                                    <b-col md="4" v-for="upload, index in showRightPhotos" :key="upload.path">
                                                        <a v-b-modal.fundus-modal v-on:click="selectedEye = 'right'; canvasImageSet(upload?.path); selIndex = index;">
                                                            <img class="fundus-image" :src="upload?.path" />
                                                            <p>{{upload.type}}</p>
                                                        </a>
                                                    </b-col>
                                                </b-row>
                                                <b-row class="flex m-5 text-md-left">
                                                    <b-col v-if="$supabase.auth.currentUser.app_metadata.doctor_type == 'retinal'" md="6">
                                                        <b-form-group label="Diyabetik Retinopati" v-slot="{ ariaDescribedbyDR }" id="formGroupDRRight">
                                                            {{diagnosis.doctor.right_eye}}
                                                            <b-form-radio class="checked-has-not" v-model="diagnosis.doctor.right_has_dr" :aria-describedby="ariaDescribedbyDR" name="right_has_dr" value="noDR">No Apparent DR</b-form-radio>
                                                            <b-form-radio class="checked-has" v-model="diagnosis.doctor.right_has_dr" :aria-describedby="ariaDescribedbyDR" name="right_has_dr" value="mtmDR">More than Mild DR</b-form-radio>
                                                            <b-form-radio class="checked-has" v-model="diagnosis.doctor.right_has_dr" :aria-describedby="ariaDescribedbyDR" name="right_has_dr" value="vtDR">Vision Threatening DR</b-form-radio>
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col md="6">
                                                        <b-form-group class="flex" label="Glokom" v-slot="{ ariaDescribedbyGL }" id="formGroupGLRight">
                                                            <b-form-radio class="checked-has-not" v-model="diagnosis.doctor.right_has_glaucoma" :aria-describedby="ariaDescribedbyGL" name="right_has_glaucoma" value="noGL">Glokom Yok</b-form-radio>
                                                            <b-form-radio class="checked-has" v-model="diagnosis.doctor.right_has_glaucoma" :aria-describedby="ariaDescribedbyGL" name="right_has_glaucoma" value="avgGL">Glokom Şüphesi Var</b-form-radio>
                                                            <b-form-radio class="checked-has" v-model="diagnosis.doctor.right_has_glaucoma" :aria-describedby="ariaDescribedbyGL" name="right_has_glaucoma" value="highGL">Ciddi Glokom Şüphesi</b-form-radio>
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col v-if="$supabase.auth.currentUser.app_metadata.doctor_type == 'retinal'" md="6">
                                                        <b-form-group class="flex" label="Diyabetik Makula Ödemi" v-slot="{ ariaDescribedbyDME }" id="formGroupDMERight">
                                                            <b-form-radio class="checked-has-not" v-model="diagnosis.doctor.right_has_dme" :aria-describedby="ariaDescribedbyDME" name="right_has_dme" :value="false">Yok</b-form-radio>
                                                            <b-form-radio class="checked-has" v-model="diagnosis.doctor.right_has_dme" :aria-describedby="ariaDescribedbyDME" name="right_has_dme" :value="true">Var</b-form-radio>
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col v-if="$supabase.auth.currentUser.app_metadata.doctor_type == 'retinal'" md="6">
                                                        <b-form-group class="flex" label="Retinal Ven Oklüzyonu" v-slot="{ ariaDescribedbyRVO }" id="formGroupRVORight">
                                                            <b-form-radio class="checked-has-not" v-model="diagnosis.doctor.right_has_rvo" :aria-describedby="ariaDescribedbyRVO" name="right_has_rvo" :value="false">Yok</b-form-radio>
                                                            <b-form-radio class="checked-has" v-model="diagnosis.doctor.right_has_rvo" :aria-describedby="ariaDescribedbyRVO" name="right_has_rvo" :value="true">Var</b-form-radio>
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col v-if="$supabase.auth.currentUser.app_metadata.doctor_type == 'retinal'" md="6">
                                                        <b-form-group label="Yaşa Bağlı Makula Dejenerasyonu" v-slot="{ ariaDescribedbyARMD }" id="formGroupARMDRight">
                                                            <b-form-radio @change="patientAgePopup()" class="checked-has-not" v-model="diagnosis.doctor.right_has_armd" :aria-describedby="ariaDescribedbyARMD" name="right_has_armd" value="noARMD">Yok</b-form-radio>
                                                            <b-form-radio :disabled="demographics.age < 55" @change="patientAgePopup()" class="checked-has" v-model="diagnosis.doctor.right_has_armd" :aria-describedby="ariaDescribedbyARMD" name="right_has_armd" value="nrefARMD">Non Referable ARMD</b-form-radio>
                                                            <b-form-radio :disabled="demographics.age < 55" @change="patientAgePopup()" class="checked-has" v-model="diagnosis.doctor.right_has_armd" :aria-describedby="ariaDescribedbyARMD" name="right_has_armd" value="refARMD">Referable ARMD</b-form-radio>
                                                        </b-form-group>
                                                        <div class="text-danger" v-if="demographics.age < 55">
                                                            <b-icon icon="exclamation-circle-fill"></b-icon>
                                                            <span> 55 yaş altı hastalarda ARMD teşhisi yapılamaz.</span>
                                                        </div>
                                                    </b-col>
                                                    <b-col v-if="$supabase.auth.currentUser.app_metadata.doctor_type == 'retinal'" md="6">
                                                        <b-form-group class="flex" label="Diğer Retinal Hastalıklar" v-slot="{ ariaDescribedbyRR }" id="formGroupRRRight">
                                                            <b-form-radio class="checked-has-not" v-model="diagnosis.doctor.right_has_gd" :aria-describedby="ariaDescribedbyRR" name="right_has_gd" :value="false">Yok</b-form-radio>
                                                            <b-form-radio class="checked-has" v-model="diagnosis.doctor.right_has_gd" :aria-describedby="ariaDescribedbyRR" name="right_has_gd" :value="true">Var</b-form-radio>
                                                        </b-form-group>
                                                    </b-col>
                                                </b-row>
                                                    <b-form-checkbox
                                                        id="checkbox-od-right"
                                                        v-model="right_od_not_evaluated"
                                                        @change="(event) => notEvaluated(event, 'right', false, true)"
                                                        name="checkbox-od-right"
                                                        :value="true"
                                                        :unchecked-value="false"
                                                    >
                                                        Sağ Göz Optik Disk Değerlendirilemiyor.
                                                    </b-form-checkbox>
                                                    <b-form-checkbox
                                                        id="checkbox-macula-right"
                                                        v-model="right_macula_not_evaluated"
                                                        @change="(event) => notEvaluated(event, 'right', true, false)"
                                                        name="checkbox-macula-right"
                                                        :value="true"
                                                        :unchecked-value="false"
                                                    >
                                                        Sağ Göz Makula Değerlendirilemiyor.
                                                    </b-form-checkbox>
                                            </b-col>
                                            <b-col v-if="uploads.left.length !== 0"  md="6">
                                                <label for="diagnosis_right_eye" class="col-form-label font-weight-bold"> SOL GÖZ FOTOĞRAFLARI (Toplam: {{uploads.left.length}} görsel)</label>
                                                <b-row class="m-5">
                                                    <b-col md="4" v-for="upload, index in showLeftPhotos" :key="upload.path">
                                                        <a v-b-modal.fundus-modal v-on:click="selectedEye = 'left'; canvasImageSet(upload?.path); selIndex = index;">
                                                            <img class="fundus-image" :src="upload?.path" />
                                                            <p>{{upload.type}}</p>
                                                        </a>
                                                    </b-col>
                                                </b-row>
                                                <b-row class="flex m-5 text-md-left">
                                                    <b-col v-if="$supabase.auth.currentUser.app_metadata.doctor_type == 'retinal'" md="6">
                                                        <b-form-group label="Diyabetik Retinopati" v-slot="{ ariaDescribedbyDR }" id="formGroupDRLeft">
                                                            <b-form-radio class="checked-has-not" v-model="diagnosis.doctor.left_has_dr" :aria-describedby="ariaDescribedbyDR" name="left_has_dr" value="noDR">No Apparent DR</b-form-radio>
                                                            <b-form-radio class="checked-has" v-model="diagnosis.doctor.left_has_dr" :aria-describedby="ariaDescribedbyDR" name="left_has_dr" value="mtmDR">More than Mild DR</b-form-radio>
                                                            <b-form-radio class="checked-has" v-model="diagnosis.doctor.left_has_dr" :aria-describedby="ariaDescribedbyDR" name="left_has_dr" value="vtDR">Vision Threatening DR</b-form-radio>
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col md="6">
                                                        <b-form-group class="flex" label="Glokom" v-slot="{ ariaDescribedbyGL }" id="formGroupGLLeft">
                                                            <b-form-radio class="checked-has-not" v-model="diagnosis.doctor.left_has_glaucoma" :aria-describedby="ariaDescribedbyGL" name="left_has_glaucoma" value="noGL">Glokom Yok</b-form-radio>
                                                            <b-form-radio class="checked-has" v-model="diagnosis.doctor.left_has_glaucoma" :aria-describedby="ariaDescribedbyGL" name="left_has_glaucoma" value="avgGL">Glokom Şüphesi Var</b-form-radio>
                                                            <b-form-radio class="checked-has" v-model="diagnosis.doctor.left_has_glaucoma" :aria-describedby="ariaDescribedbyGL" name="left_has_glaucoma" value="highGL">Ciddi Glokom Şüphesi</b-form-radio>
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col v-if="$supabase.auth.currentUser.app_metadata.doctor_type == 'retinal'" md="6" id="formGroupDMELeft">
                                                        <b-form-group class="flex" label="Diyabetik Makula Ödemi" v-slot="{ ariaDescribedbyDME }">
                                                            <b-form-radio class="checked-has-not" v-model="diagnosis.doctor.left_has_dme" :aria-describedby="ariaDescribedbyDME" name="left_has_dme" :value="false">Yok</b-form-radio>
                                                            <b-form-radio class="checked-has" v-model="diagnosis.doctor.left_has_dme" :aria-describedby="ariaDescribedbyDME" name="left_has_dme" :value="true">Var</b-form-radio>
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col v-if="$supabase.auth.currentUser.app_metadata.doctor_type == 'retinal'" md="6">
                                                        <b-form-group class="flex" label="Retinal Ven Oklüzyonu" v-slot="{ ariaDescribedbyRVO }" id="formGroupRVOLeft">
                                                            <b-form-radio class="checked-has-not" v-model="diagnosis.doctor.left_has_rvo" :aria-describedby="ariaDescribedbyRVO" name="left_has_rvo" :value="false">Yok</b-form-radio>
                                                            <b-form-radio class="checked-has" v-model="diagnosis.doctor.left_has_rvo" :aria-describedby="ariaDescribedbyRVO" name="left_has_rvo" :value="true">Var</b-form-radio>
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col v-if="$supabase.auth.currentUser.app_metadata.doctor_type == 'retinal'" md="6">
                                                        <b-form-group label="Yaşa Bağlı Makula Dejenerasyonu" v-slot="{ ariaDescribedbyARMD }" id="formGroupARMDLeft">
                                                            <b-form-radio @change="patientAgePopup()" class="checked-has-not" v-model="diagnosis.doctor.left_has_armd" :aria-describedby="ariaDescribedbyARMD" name="left_has_armd" value="noARMD">Yok</b-form-radio>
                                                            <b-form-radio :disabled="demographics.age < 55" @change="patientAgePopup()" class="checked-has" v-model="diagnosis.doctor.left_has_armd" :aria-describedby="ariaDescribedbyARMD" name="left_has_armd" value="nrefARMD">Non Referable ARMD</b-form-radio>
                                                            <b-form-radio :disabled="demographics.age < 55" @change="patientAgePopup()" class="checked-has" v-model="diagnosis.doctor.left_has_armd" :aria-describedby="ariaDescribedbyARMD" name="left_has_armd" value="refARMD">Referable ARMD</b-form-radio>
                                                        </b-form-group>
                                                        <div class="text-danger" v-if="demographics.age < 55">
                                                            <b-icon icon="exclamation-circle-fill"></b-icon>
                                                            <span> 55 yaş altı hastalarda ARMD teşhisi yapılamaz.</span>
                                                        </div>
                                                    </b-col>
                                                    <b-col v-if="$supabase.auth.currentUser.app_metadata.doctor_type == 'retinal'" md="6">
                                                        <b-form-group class="flex" label="Diğer Retinal Hastalıklar" v-slot="{ ariaDescribedbyRR }" id="formGroupRRLeft">
                                                            <b-form-radio class="checked-has-not" v-model="diagnosis.doctor.left_has_gd" :aria-describedby="ariaDescribedbyRR" name="left_has_gd" :value="false">Yok</b-form-radio>
                                                            <b-form-radio class="checked-has" v-model="diagnosis.doctor.left_has_gd" :aria-describedby="ariaDescribedbyRR" name="left_has_gd" :value="true">Var</b-form-radio>
                                                        </b-form-group>
                                                    </b-col>
                                                </b-row>
                                                    <b-form-checkbox
                                                        id="checkbox-od-left"
                                                        v-model="left_od_not_evaluated"
                                                        @change="(event) => notEvaluated(event, 'left', false, true)"
                                                        name="checkbox-od-left"
                                                        :value="true"
                                                        :unchecked-value="false"
                                                    >
                                                        Sol Göz Optik Disk Değerlendirilemiyor.
                                                    </b-form-checkbox>
                                                    <b-form-checkbox
                                                        id="checkbox-macula-left"
                                                        v-model="left_macula_not_evaluated"
                                                        @change="(event) => notEvaluated(event, 'left', true, false)"
                                                        name="checkbox-macula-left"
                                                        :value="true"
                                                        :unchecked-value="false"
                                                    >
                                                        Sol Göz Makula Değerlendirilemiyor.
                                                    </b-form-checkbox>
                                            </b-col>
                                        </b-row>
                                        <h4 v-else>
                                            <div>
                                                Hastaya ait resim bulunamadı!
                                            </div>
                                        </h4>
                                        <b-row class="mb-5">
                                            <b-col md="12">
                                                <div class="float-right mb-5 mr-2">
                                                    <b-button
                                                        size="md"
                                                        variant="primary"
                                                        class="btn btn-wide btn-primary mr-3"
                                                        v-on:click="upsertDiagnosisModal"
                                                    >
                                                        <span v-show="!loading"> İmzala & Kaydet  </span>
                                                        <div v-show="loading" class="spinner-border spinner-border-sm"
                                                             role="status">
                                                            <span class="sr-only">Yükleniyor...</span>
                                                        </div>
                                                    </b-button>
                                                </div>
                                            </b-col>
                                        </b-row>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>

                            <!-- UPDATE AREA FOR SUPER ADMIN DOCTOR ROLE -->
                            <b-card v-show="(getRole === 'doctor' && isSuper === true)" no-body class="mb-1">
                                <b-card-header
                                    header-tag="header"
                                    class="p-1"
                                    role="tab"
                                    @click="jumpToSection(8)"
                                >
                                    <div class="p-3 d-block">
                                        <h5 class="card-title font-weight-bold d-inline">Tüm Teşhisler</h5>
                                        <b class="ml-5" v-if="demographics.single_eye">(Tek Göz)</b>
                                    </div>
                                </b-card-header>
                                <b-collapse
                                    id="accordion8"
                                    :visible="step === 7"
                                    accordion="my-accordion"
                                    role="tabpanel"
                                >
                                    <b-card-body>
                                        <b-row class="m-5">
                                            <b-col md="6" sm="12">
                                                <div class="">
                                                    <p class="font-weight-bold">Sol Göz Fotoğrafları:</p>
                                                    <div v-for="uploadedImage in uploads.left"
                                                         v-bind:key="uploadedImage.id">
                                                        <b-img thumbnail fluid v-img:group1
                                                               v-bind:src="uploadedImage.path"/>
                                                    </div>
                                                </div>
                                            </b-col>
                                            <b-col md="6" sm="12">
                                                <div class="">
                                                    <p class="font-weight-bold">Sağ Göz Fotoğrafları:</p>
                                                    <div v-for="uploadedImage2 in uploads.right"
                                                         v-bind:key="uploadedImage2.id">
                                                        <b-img height="100px" thumbnail fluid v-img:group2
                                                               v-bind:src="uploadedImage2.path"/>
                                                    </div>
                                                </div>
                                            </b-col>
                                        </b-row>
                                        <b-row class="m-5" v-for="(diagnose, key) in diagnoses"
                                               v-bind:key="diagnose.id">
                                            <b-col md="6" sm="12">
                                                <label
                                                    for="diagnosis_left_eye"
                                                    class="col-form-label font-weight-bold"
                                                > Sol Göz Teşhisleri:
                                                </label>
                                                <div class="my-2">
                                                    <select
                                                        v-model="diagnoses[key].left_eye"
                                                        class="form-control"
                                                    >
                                                        <option v-bind:value="undefined" disabled>
                                                            Sol Göz
                                                        </option>

                                                        <option value="no_apparent_dr">No apparent DR</option>
                                                        <option value="mild_dr">Mild DR</option>
                                                        <option value="moderate_dr">Moderate DR</option>
                                                        <option value="severe_dr">Severe DR</option>
                                                        <option value="profilerative_dr">Profilerative DR</option>
                                                    </select>
                                                    <div class="my-2">
                                                        <b-form-checkbox
                                                            :disabled="getRole === 'coordinator' || getRole === 'cra' || isModerator"
                                                            v-model="diagnoses[key].left_has_dme"
                                                            :value="true"
                                                            :unchecked-value="false"
                                                        >
                                                            DME var
                                                        </b-form-checkbox>
                                                    </div>
                                                </div>
                                            </b-col>
                                            <b-col md="6" sm="12">
                                                <div>
                                                    <label
                                                        for="diagnosis_right_eye"
                                                        class="col-form-label font-weight-bold"
                                                    > Sağ Göz Teşhisleri:
                                                    </label>

                                                    <div class="my-2">
                                                        <select
                                                            name="diagnosis_right_eye"
                                                            v-model="diagnoses[key].right_eye"
                                                            class="form-control"

                                                        >
                                                            <option v-bind:value="undefined" disabled>
                                                                Sağ Göz
                                                            </option>
                                                            <option value="no_apparent_dr">No apparent DR</option>
                                                            <option value="mild_dr">Mild DR</option>
                                                            <option value="moderate_dr">Moderate DR</option>
                                                            <option value="severe_dr">Severe DR</option>
                                                            <option value="profilerative_dr">Profilerative DR</option>
                                                        </select>
                                                        <div class="my-2">
                                                            <b-form-checkbox
                                                                :disabled="getRole === 'coordinator' || getRole === 'cra' || isModerator"
                                                                v-model="diagnoses[key].right_has_dme"
                                                                :value="true"
                                                                :unchecked-value="false"
                                                            >
                                                                DME var
                                                            </b-form-checkbox>
                                                        </div>
                                                    </div>
                                                </div>
                                            </b-col>
                                        </b-row>
                                        <b-row class="mb-5">
                                            <b-col md="12">
                                                <div class="float-right mb-5 mr-2">
                                                    <b-button
                                                        size="md"
                                                        variant="primary"
                                                        class="btn btn-wide btn-primary mr-3"
                                                        v-on:click="updateDiagnoses"
                                                    >
                                                        <span v-show="!loading"> İmzala & Kaydet  </span>
                                                        <div v-show="loading" class="spinner-border spinner-border-sm"
                                                             role="status">
                                                            <span class="sr-only">Yükleniyor...</span>
                                                        </div>
                                                    </b-button>
                                                </div>
                                            </b-col>
                                        </b-row>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>

                        </b-form>
                    </div>
                </div>
            </template>

            <div v-show="!(getRole === 'doctor')" class="position-relative form-check m-3 mb-5">
                <b-row>
                    <!-- Confirm Check Box (Digital Signature) -->
                    <b-col md="6">
                        <b-form-checkbox
                            id="checkbox-3"
                            v-model="demographics.signature"
                            name="checkbox-3"
                            :value="'i consent'"
                            :unchecked-value="null"
                        >
                            <p v-if="getRole === 'coordinator'"> Bu ekrandaki verileri kontrol ettim, 
                                sisteme girilen bilgilerin formlar ile uyumlu olduğunu onaylıyorum *</p>
                            <p v-else-if="getRole === 'cra'">Yukarıda girilmiş bilgileri kontrol ettim, uygunluğunu
                                onaylıyorum *</p>
                        </b-form-checkbox>
                        <b-form-checkbox
                            id="checkbox-4"
                            v-model="demographics.consentForm"
                            name="checkbox-4"
                            :value="'i consent'"
                            :unchecked-value="null"
                            v-show="(getRole === 'coordinator' || getRole === 'cra') && this.patientEligible"
                        >
                            <p> Onam formu imzalatılmıştır *</p>
                        </b-form-checkbox>
                    </b-col>
                    <!-- Save & Confirm Button -->
                    <b-col md="6">
                        <div class="float-right">
                            <b-button
                                :disabled="!canSign"
                                variant="primary"
                                v-on:click="sign"
                                class="btn btn-wide btn-primary mr-3"
                                v-if="getRole !== 'doctor'"
                            >İmzala ve Kaydet
                            </b-button>
                        </div>
                        <div class="p-5">
                            <p class="text-danger mt-3" v-if="(getRole === 'coordinator' || getRole === 'cra') && !canSign">
                                * Lütfen İmzalamadan önce gerekli tüm alanları doldurunuz.
                            </p>
                            <p class="text-danger" v-if="(getRole === 'coordinator' || getRole === 'cra') && !photosUploaded">
                                * Lütfen EyeCheckup teşhislerini ve resimlerini kaydettiğinizden emin olunuz.
                            </p>
                        </div>
                    </b-col>
                </b-row>
            </div>

            <!-- Successfully Saved Modal -->
            <b-modal
                id="successModal"
                hide-footer
                hide-header
                size="sm"
                title="Success!"
            >
                <div class="d-block text-center">
                    <b-icon
                        icon="check-circle-fill"
                        animation="cylon"
                        variant="success"
                        font-scale="4"
                    ></b-icon>
                    <h1 class="font-weight-bold">Başarılı!</h1>
                    <v-divider></v-divider>
                    <p class="modalp">Tüm bilgiler başarı ile onaylanmıştır.</p>
                    <p class="modalp">Gönüllü Protokol No:</p>
                    <h4 class="text-success">{{ this.demographics.protocol_no }}</h4>
                </div>
                <b-button
                v-if="getRole === 'doctor'"
                type="button" block 
                variant="primary"
                class="btn btn-wide btn-primary mt-5"
                @click="$router.push('/diagnosis')">Tamam</b-button>
                <b-button
                v-if="getRole === 'coordinator'"
                type="button" block 
                variant="primary"
                class="btn btn-wide btn-primary mt-5"
                @click="$router.push('/detailed-search')">Tamam</b-button>
            </b-modal>


            <!-- Error Modal for network & other errors -->
            <b-modal id="generalErrorModal" hide-footer size="md"
                     title="">

                <div class="d-block text-center">
                    <b-icon
                        icon="x-circle-fill"
                        animation="cylon"
                        variant="danger"
                        font-scale="4"
                    ></b-icon>
                    <h3 class="font-weight-bold mt-2">Hata!</h3>
                    <v-divider></v-divider>
                    <p class="modalp">Beklenmedik bir hata oluştu.</p>
                    <p class="modalp">{{ error }}</p>
                </div>
            </b-modal>
            <b-modal id="generalWarningModal" hide-footer size="lg">
                <div class="d-block text-center">
                    <b-icon
                        icon="exclamation-circle-fill"
                        animation="cylon"
                        variant="warning"
                        font-scale="4"
                    ></b-icon>
                    <h3 class="font-weight-bold mt-2">Bilgilendirme!</h3>
                    <v-divider></v-divider>
                    <div class="modalp">
                        <p> WiseCRF’te izlenen kişisel sağlık verileri T.C. Sağlık Bakanlığı’nın 21.06.2019 Resmî Gazete’de yayımlanan Kişisel Sağlık Verileri Hakkındaki Yönetmeliği ile uyumlu olarak; </p> 
                        <p>    a. Kişisel verilerin; kimliği belirli veya belirlenebilir gerçek kişiyle ilişkilendirilmemesi için sistemde kişi ad, soyad, TC kimlik no gibi kişisel veriler tutulmamakta, çalışmanın gerçek verilere dayandırıldığının ispatı için sadece Hasta Protokol No bilgisi tutulmaktadır. WiseCRF sisteminde muhafaza edilen bilgilerin, herhangi bir kişiyle ilişkilendirilemeyecek şekilde işlenmesi esas alınmaktadır. </p>
                        <p>    b. İlgili yönetmeliğin anonim hâle gerilen kişisel sağlık verileri ile bilimsel çalışma yapılabileceği maddesi doğrultusunda WiseCRF sisteminde tutulan kişisel sağlık verileri tamamen anonim hale getirilmekte ve çalışmaya katılan kişilerin özel hayatının gizliliğini veya kişilik haklarını ihlâl etmeyecek şekilde bilimsel amaçlarla işlenmektedir. </p>
                        <p> WiseCRF sisteminde tutulan verilerin bilgi güvenliğini sağlamak amacıyla role bağlı yetkilendirme ile sistemdeki yetki ve sorumluluklar ayrıştırılmış, bilgi güvenliği için gereken önlemler alınmıştır. </p>
                    </div>
                </div>
                <b-button
                type="button" block 
                variant="primary"
                class="btn btn-wide btn-primary mt-5"
                @click="dontShowWeek">Bu Hafta Tekrar Gösterme</b-button>
            </b-modal>
            <div v-show="loadingSpinner">
                <div class="loading-spinner"></div>
                <b-spinner class="align-middle position-fixed"></b-spinner>
            </div>
        </div>
          <b-modal size="xl" id="modal-scoped">
            <template #modal-header>
                <h5>Değişiklikler Kaydedilsin mi?</h5>
            </template>

            <template #default>
                <p>Gönüllüde teşhis edilen hastalıklar:</p>
                <b-row class="d-flex m-3">
                    <div class="text-center font-weight-bold m-5">SAĞ GÖZ</div>
                    <div class="text-center font-weight-bold m-5">SOL GÖZ</div>
                </b-row>
                <b-row class="d-flex m-3">
                    <b-col md="6" class="vertical-hr" name="right">
                        <div v-if="doctorDiagnoses.right.length > 0">
                            <div class="ml-5" v-for="diagnose in doctorDiagnoses.right" :key="diagnose">{{diagnose}}</div>
                        </div>
                        <div v-else-if="diagnosis.doctor.right_has_not_evaluated">
                            <div class="ml-5">Sağ Göz Değerlendirilemedi.</div>
                        </div>
                        <div v-else>
                            <div class="ml-5">Teşhis Yapılmadı.</div>
                        </div>
                    </b-col>
                    <b-col md="6" name="left">
                        <div v-if="doctorDiagnoses.left.length > 0">
                            <div class="ml-5" v-for="diagnose in doctorDiagnoses.left" :key="diagnose">{{diagnose}}</div>
                        </div>
                        <div v-else-if="diagnosis.doctor.left_has_not_evaluated">
                            <div class="ml-5">Sol Göz Değerlendirilemedi.</div>
                        </div>
                        <div v-else>
                            <div class="ml-5">Teşhis Yapılmadı.</div>
                        </div>
                    </b-col>
                </b-row>
            </template>

            <template #modal-footer="{ cancel }">
                <b-button size="sm" variant="success" v-on:click="upsertDiagnosis">
                    Kaydet
                </b-button>
                <b-button size="sm" variant="danger" @click="cancel()">
                    Vazgeç
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
import {validationMixin} from "vuelidate";
import {required} from "vuelidate/lib/validators";
import VueUploadMultipleImage from "vue-upload-multiple-image";
import base64 from "base64-js";
import { SYSTEM_TYPE } from "@/main";

export default {
    components: {
        VueUploadMultipleImage: VueUploadMultipleImage,
    },
    name: "DemographicsPage",
    created() {
        // window.onhashchange = function(){
        //     return 'Sayfada kaydedilmemiş değişiklikler olabilir. Sayfadan ayrılmak istediğinize emin misiniz?';
        // };
        // window.onbeforeunload = function() {
        //     return 'Sayfada kaydedilmemiş değişiklikler olabilir. Sayfayı yenilemek istediğinize emin misiniz?';
        // }
    },
    beforeDestroy() {
        // window.onhashchange = null
        // window.onbeforeunload = null
    },
    async mounted() {
        document.addEventListener("keyup", this.galleryNavigation);
        if(this.generalWarningModal) {
            this.$bvModal.show("generalWarningModal");
        }
        const id = this.$router.currentRoute.params.id;
        if (id) {
            this.patient_id = id;

            this.getCraStatus();

            const {data: demographics} = await this.$supabase
                .from("patient")
                .select("*")
                .eq("id", id)
                .single();
            this.demographics = demographics;
            if(!demographics) {
                this.error = 'Gönüllü Bulunamadı!';
                this.$bvModal.show('generalErrorModal');
            }


            const {data: medical_history} = await this.$supabase
                .from("medical_history")
                .select("*")
                .eq("patient", id)
                .single();
            if (medical_history) {
                this.medical_history = medical_history;
                // this.step = 1;
            }

            const {data: inclusion} = await this.$supabase
                .from("inclusion_criteria")
                .select("*")
                .eq("patient", id)
                .single();
            if (inclusion) {
                this.inclusion = inclusion;
                // this.step = 2;
            }

            const {data: exclusion} = await this.$supabase
                .from("exclusion_criteria")
                .select("*")
                .eq("patient", id)
                .single();
            if (exclusion) {
                this.exclusion = exclusion;
                // this.step = 3;
            }

            const {data: uploads} = await this.$supabase
                .from("upload")
                .select("*")
                .match({
                    patient: this.patient_id,
                });

            if (uploads.length > 0) {
                this.photosUploaded = true
            }
            for (const upload of uploads) {
                // const {signedURL} = await this.$supabase.storage
                //     .from("eyecheckup")
                //     .createSignedUrl(upload.object, 60);
                if (upload.type !== 'MACULA_CROPPED') {
                    this.uploads[upload.side].push({
                        ...upload,
                        path: this.staticPath + upload.object,
                    });
                }
                // this.step = 4;
            }


            const {data: diagnosises} = await this.$supabase
                .from("diagnosis")
                .select("*")
                .in("type", ["topcon", "canon", "optomed", "doctor"])
                .match({
                    patient: this.patient_id,
                });

            for (const diagnosis of diagnosises) {
                if (diagnosis.type === 'doctor' && diagnosis.by !== this.$supabase.auth.currentUser.id) {
                    this.diagnoses.push(diagnosis);
                } else {
                    this.diagnosis[diagnosis.type] = diagnosis;
                    Object.entries(diagnosis).forEach(([key, value]) => {
                        if ((key.includes('left') || key.includes('right')) && (value != null)) {
                            let _item = key.includes('_has_') ? key.split('_has_') : null;
                            this.diagnosisItemsMap = {
                                ...this.diagnosisItemsMap,
                                [_item[1].toUpperCase()] : {
                                    ...this.diagnosisItemsMap[_item[1].toUpperCase()],
                                    diagnosisName: _item[1].toUpperCase().split('_').join(' '),
                                    [`${_item[0]}_${diagnosis.type}`] : value == false ? 'YOK' : value == true ? 'VAR' : value // TODO: Its not good change here
                                }
                            }
                        }
                    });
                }
            }
            this.diagnosisItems = []

            Object.values(this.diagnosisItemsMap).forEach((item) => {
                this.diagnosisItems.push(item)
            })
            this.getRole === 'doctor' ? this.step = 6 : this.step = 0;
            this.loadingSpinner = false;

        } else {
            this.loadingSpinner = false;
        }       
        // this.assertEligible();

    },
    data() {
        return {
            dragMode: false,
            photosUploaded: false,
            selIndex : 0,
            patientEligible : true,
            loadingSpinner: true,
            error: '',
            status: {},
            diagnoses: [],
            left_macula_not_evaluated: false,
            right_macula_not_evaluated: false,
            left_od_not_evaluated: false,
            right_od_not_evaluated: false,
            diagnoseNames: {
                'dr' : 'Diyabetik Retinopati',
                'glaucoma': 'Glokom',
                'dme': 'Diyabetik Makula Ödemi',
                'rvo': 'Retinal Ven Oklüzyonu',
                'armd': 'Yaşa Bağlı Makula Dejenerasyonu',
                'gd': 'Diğer Retinal Hastalıklar'
            },
            anomalyNames: {
                'HIGHGL': 'Ciddi Glokom Şüphesi',
                'AVGGL': 'Glokom Şüphesi Var',
                'REFARMD': 'Ciddi ARMD Şüphesi',
                'NREFARMD': 'ARMD Şüphesi Var',
                'MTMDR':'More Than Mild',
                'VTDR': 'Vision Threatening',
                'TRUE': 'Var',
                'NULL': 'Değerlendirilemedi'
            },
            doctorDiagnoses: {
                right: [],
                left: []
            },
            showContentForm: false,
            readonly: false,
            loading: false,
            step: 0,
            patient_id: undefined,
            currentHistoryTarget: undefined,

            demographics: {
                date_of_visit: new Date().toISOString().substr(0, 10)
            },
            canvasImage: null,
            dragStep: 0,
            medical_history: {
            },
            configKonva: {
                width: window.innerWidth / 3,
                height: window.innerHeight / 1.6,
                scaleX: 1,
                scaleY: 1,
            },
            lines: {
                cup: {
                    startX: null,
                    startY: null,
                    endX: undefined,
                    endY: undefined
                },
                disc: {
                    startX: null,
                    startY: null,
                    endX: undefined,
                    endY: undefined
                },
            },
            isDrawing: false,
            inclusion: {},
            exclusion: {},

            uploads: {
                left: [],
                right: [],
            },
            uploadCounter: 0,
            diagnosis: {
                topcon: {},
                canon: {},
                optomed: {},
                doctor: {
                    right_has_dr: "noDR", // diabetic
                    right_has_glaucoma: "noGL",
                    right_has_dme: false, // diabetic
                    right_has_rvo: false,
                    right_has_armd: "noARMD",
                    right_has_gd: false,
                    right_has_not_evaluated: false,

                    left_has_dr: "noDR", // diabetic
                    left_has_glaucoma: "noGL",
                    left_has_dme: false, // diabetic
                    left_has_rvo: false,
                    left_has_armd: "noARMD",
                    left_has_gd: false,
                    left_has_not_evaluated: false,
                }
            },

            drLevel: {
                "noDR" : "no_apparent_dr",
                "mtmDR" : "more_than_mild_dr",
                "vtDR" : "vision_threatening_dr"
            },
            menu: false,
            modal: false,
            menu2: false,
            success: false,
            isProtocolNoUnique: true,
            currentYear: new Date().getFullYear(),
            selectedEye: 'left',
            getDiagnosesButtonDisabled: false,
            getImageButtonDisabled: false,
            // for table
            diagnosisItemsMap: {},
            diagnosisItems: [],
            // for table
            diagnosisFields: [
                { key: 'diagnosisName', variant: 'secondary' },
                { key: 'left_topcon', label: 'LEFT TOPCON' },
                { key: 'left_optomed', label: 'LEFT OPTOMED' },
                { key: 'left_canon', label: 'LEFT CANON' },
                { key: 'right_topcon', label: 'RIGHT TOPCON' },
                { key: 'right_optomed', label: 'RIGHT OPTOMED' },
                { key: 'right_canon', label: 'RIGHT CANON' },
            ],
        };
    },

    // Validations : All fields are required
    mixins: [validationMixin],
    validations() {
        return {
            diagnosis: {
                doctor: {},
                optomed: {required},
                topcon: {required},
                canon: {required},
            },
            demographics: {
                protocol_no: {required},
                name_surname: {required},
                gender: {required},
                age: {required},
                weight: {required},
                height: {required},
                date_of_visit: {required},
                single_eye: {required},
                has_dripped: {required},
                has_problem: {required},
                has_cigarette: {required},
                did_come_from: {required},
            },
            medical_history: {
                is_diabetes: {required},
                eye_pressure: {required},
                is_glaucoma: {required},
                has_family_amd: {required},
                other_diseases: {required},
            },
            inclusion: {
                inclusion1: {required},
                inclusion2: {required},
                inclusion3: {required},
                inclusion4: {required}
            },

            exclusion: {
                exclusion1: {required},
                exclusion2: {required},
                exclusion3: {required},
                exclusion4: {required},
                exclusion5: {required}
            },
            diagnoses: {
                $each: {
                    left_eye: {},
                    left_has_dme: {},
                    right_eye: {},
                    right_has_dme: {},
                }
            },
        }
    },

    computed: {
        cupDiscRatio: function () {
            let cupHeight = Math.sqrt( Math.pow(this.lines.cup.startX - this.lines.cup.endX, 2) + Math.pow(this.lines.cup.startY - this.lines.cup.endY, 2))
            let discHeight = Math.sqrt( Math.pow(this.lines.disc.startX - this.lines.disc.endX, 2) + Math.pow(this.lines.disc.startY - this.lines.disc.endY, 2))
            if (cupHeight > discHeight) {
                return (discHeight / cupHeight).toFixed(3)
            } else {
                return (cupHeight / discHeight).toFixed(3)
            }
        },
        showRightPhotos: function() {
            return this.uploads.right.slice(0, 3)
        },
        showLeftPhotos: function() {
            return this.uploads.left.slice(0, 3)
        },
        generalWarningModal() {
            let data = JSON.parse(localStorage.getItem("_gnWMd"));
            var today = new Date();
            var dd = today.getDate();
            
            if(data){
                var showToday = dd - data.dt
                if (showToday > 7 || showToday < 0) {
                data = true;
                } else {
                    data = false;
                }
            } else {
                data = true
            }

            return data
        },
        staticPath: function () {
            return `https://static.wisecrf.com/`
        },
        bmiCalculation: function () {
            var weight = parseInt(this.demographics.weight);
            var height = parseInt(this.demographics.height) / 100;
            var bmi = weight / (height * height);
            return bmi.toFixed(2);
        },
        heightControl: function () {
            if (this.demographics.height < 100 || this.demographics.height == null) {
                return false;
            }
            return true
        },
        ageControl: function () {
            if (this.demographics.age < 18 || this.demographics.age == null) {
                return false;
            }
            return true
        },
        isSuper: function () {
            return this.$supabase.auth.currentUser.app_metadata.is_super;
        },

        isModerator: function () {
            return this.$supabase.auth.currentUser.app_metadata.is_moderator;
        },

        getRole: function () {
            return this.$supabase.auth.currentUser.app_metadata.role;
        },

        buttonVisible: function () {
            if (this.getRole === 'coordinator') {
                return Object.keys(this.status).length > 0;
            } else if (this.getRole === 'cra') {
                return true;
            }
            return false;
        },

        errorToShow: function () {
            return this.error;
        },

        isChecked() {
            return this.demographics.signature === 'i consent';
        },

        consentFormChecker() {
            return this.demographics.consentForm === 'i consent';
        },

        canSign() {
            if(this.patientEligible) {
                return this.getRole !== 'doctor' ? this.consentFormChecker && this.isChecked && !this.$v.demographics.$invalid && !this.$v.medical_history.$invalid
                    && !this.$v.inclusion.$invalid && !this.$v.exclusion.$invalid && !this.$v.diagnosis.$invalid && this.photosUploaded : !this.$v.demographics.$invalid 
                    && !this.$v.medical_history.$invalid && !this.$v.inclusion.$invalid && !this.$v.exclusion.$invalid && !this.$v.diagnosis.$invalid;
            } else {
                return this.isChecked && !this.$v.demographics.$invalid && !this.$v.medical_history.$invalid
                    && !this.$v.inclusion.$invalid && !this.$v.exclusion.$invalid;
            }    
        },
    },

    methods: {
        galleryNavigation : function(e) {
            var keyValue = e.keyCode ? e.keyCode : e.charCode;

            if (keyValue == 37 && !(this.selIndex == 0)) {
                this.canvasImageSet(this.uploads[this.selectedEye][this.selIndex - 1]?.path); 
                this.selIndex--
                this.resetImageScaleFromCanvas();
            } else if (keyValue == 39 && !(this.selIndex == this.uploads[this.selectedEye].length - 1)) {
                this.canvasImageSet(this.uploads[this.selectedEye][this.selIndex + 1]?.path); 
                this.selIndex++
                this.resetImageScaleFromCanvas();
            }
        },
        changeMode() {
            this.dragMode = !this.dragMode
        },
        resetLines() {
            this.dragStep = 0
            this.lines = {
                cup: {
                    startX: null,
                    startY: null,
                    endX: null,
                    endY: null
                },
                disc: {
                    startX: null,
                    startY: null,
                    endX: null,
                    endY: null
                },
            }
        },
        handleMouseDown() {
            if (this.dragMode) {
                return
            }

            this.isDrawing = true;
            const pos = this.$refs.layerRef.getStage().getRelativePointerPosition();

            if ( this.dragStep === 0 ) {
                this.lines.cup.startX = pos.x;
                this.lines.cup.startY = pos.y;
                this.lines.cup.endX = pos.x;
                this.lines.cup.endY = pos.y;
            } else if ( this.dragStep === 1 ) {
                this.lines.disc.startX = pos.x;
                this.lines.disc.startY = pos.y;
                this.lines.disc.endX = pos.x;
                this.lines.disc.endY = pos.y;
            }
            
        },
        handleMouseMove() {
            if (this.dragMode) {
                return
            }

            // no drawing - skipping
            if (!this.isDrawing) {
                return;
            }
            const stage = this.$refs.layerRef.getStage();
            const point = stage.getRelativePointerPosition();

            // add point
            if ( this.dragStep === 0 ) {
                this.lines.cup.endX = point.x; // küçük cup
                this.lines.cup.endY = point.y;
            } else if ( this.dragStep === 1 ) {
                this.lines.disc.endX = point.x; // büyük disk
                this.lines.disc.endY = point.y;
            }
        },
        handleMouseUp() {
            if (this.dragMode) {
                return
            }

            this.isDrawing = false;
            this.dragStep++;
        },
        handleMouseWheel() {
            var stage = this.$refs.layerRef.getStage();
            var scaleBy = 1.01;
            stage.on('wheel', (e) => {
                // stop default scrolling
                e.evt.preventDefault();

                var oldScale = stage.scaleX();
                var pointer = stage.getRelativePointerPosition();

                var mousePointTo = {
                    x: (pointer.x - stage.x()) / oldScale,
                    y: (pointer.y - stage.y()) / oldScale,
                };

                // how to scale? Zoom in? Or zoom out?
                let direction = e.evt.deltaY > 0 ? -1 : 1;

                // when we zoom on trackpad, e.evt.ctrlKey is true
                // in that case lets revert direction
                if (e.evt.ctrlKey) {
                direction = -direction;
                }

                var newScale = oldScale * Math.pow(scaleBy, direction);
                if (newScale < 0.9 || newScale > 10) {
                    return;
                }

                stage.scale({ x: newScale, y: newScale });

                var newPos = {
                    x: pointer.x - mousePointTo.x * newScale,
                    y: pointer.y - mousePointTo.y * newScale,
                };
                stage.position(newPos);
            });
        },
        resetImageScaleFromCanvas() {
            var stage = this.$refs.layerRef.getStage();
            stage.scale({ x: 1, y: 1 });
            stage.position({ x: 0, y: 0 });
        },
        handleMouseEnter() {
            document.addEventListener('mousewheel', this.handleMouseWheel);
        },
        handleMouseLeave() {
            document.removeEventListener('mousewheel', this.handleMouseWheel);
        },
        canvasImageSet(imgSrc) {
            this.resetLines();
            const _image = new Image();
            _image.src = imgSrc;
            _image.width = window.innerWidth / 3;
            _image.height = window.innerWidth / 3;
            this.canvasImage = _image
        },
        medicalHistoryAll() {
            this.medical_history = {
                pills: "",
                is_diabetes: false,
                diabetes_description: "",
                eye_pressure: false,
                pressure_description: "",
                is_glaucoma: false,
                glaucoma_description: "",
                has_family_amd: false,
                amd_description: "",
                other_diseases: false,
                other_diseases_description: "",
            }
        },
        inclusionAll() {
            this.inclusion = {
                inclusion1: true,
                inclusion2: true,
                inclusion3: true,
                inclusion4: true
            }
        },
        exclusionAll() {
            this.exclusion = {
                exclusion1: false,
                exclusion2: false,
                exclusion3: false,
                exclusion4: false,
                exclusion5: false
            }
        },
        jumpToSection(section) {
            // eslint-disable-next-line no-constant-condition
            if (true) {
                this.$root.$emit('bv::toggle::collapse', `accordion${section}`)
            }
        },
        async assertEligible() {
            const {data} = await this.$supabase
                .from("patient")
                .select("eligible")
                .eq("id", this.patient_id)
                .single();
            if (!data?.eligible) {
                this.showContentForm = true;
                this.error = '[BİLGİLENDİRME] Bu gönüllü klinik çalışma için uygun değildir!';
                this.$bvModal.show('generalErrorModal');
                this.patientEligible = false;
                throw new Error('Not Eligible');
            } else {
                this.patientEligible = true;
            }
        },

        // check protocol_no is unique
        checkProtocolNoUniqueness() {
            clearTimeout(this.protocolNoCheck);
            this.protocolNoCheck = setTimeout(async () => {
                const {error: notFound} = await this.$supabase
                    .from("patient")
                    .select("id")
                    .match({
                        protocol_no: this.demographics.protocol_no,
                    }).single();
                this.isProtocolNoUnique = !!notFound;
            }, 200);
        },

        async upsertPatient() {
            this.loading = true;
            const {data, error} = await this.$supabase
                .from("patient")
                .upsert({...this.demographics, bmi: this.bmiCalculation});

            if (error) {
                this.showToast("Hata", error.message, 5000, "error");
                if (error.message === 'duplicate key value violates unique constraint "patient_protocol_no_key"') {
                    this.showToast("Hata", "Bu protokol numarası ile gönüllü kaydı bulunmaktadır. Lütfen tekrar kontrol edin!", 3000, "error")
                } else {
                    this.showToast("Hata", error.message, 5000, "error")
                }
            } else {
                this.showToast("Başarılı", "Demografik Bilgiler Başarıyla Kaydedildi!", 3000, "success")
                this.demographics.id = this.patient_id = data[0].id;
            }
            this.loading = false;

            if (!error) {
                await this.assertEligible();
                this.step = 1;
            }
        },

        async upsertMedicalHistory() {
            this.loading = true;
            const {error} = await this.$supabase
                .from("medical_history")
                .upsert(
                    {
                        ...this.medical_history,
                        patient: this.patient_id,
                    },
                    {onConflict: "patient"}
                );
            this.loading = false;
            if (error) {
                this.showToast("Hata", error.message, 5000, "error")
            } else {
                this.showToast("Başarılı", "Gönüllü Tıbbi Geçmişi Başarıyla Kaydedildi!", 3000, "success")
                this.step = 2;
                await this.assertEligible();
            }
        },

        async upsertInclusion() {
            this.loading = true;
            const {data, error} = await this.$supabase
                .from("inclusion_criteria")
                .upsert(
                    {
                        ...this.inclusion,
                        patient: this.patient_id,
                    },
                    {onConflict: "patient"}
                ).single();
            this.inclusion = data;
            this.loading = false;
            if (error) {
                this.showToast("Hata", error.message, 5000, "error")
            } else {
                this.showToast("Başarılı", "Gönüllü Dahil Edilme Kriterleri Başarıyla Kaydedildi!", 3000, "success")
                this.step = 3;
                await this.assertEligible();
            }
        },

        async upsertExclusion() {
            this.loading = true;
            const {data, error} = await this.$supabase
                .from("exclusion_criteria")
                .upsert(
                    {
                        ...this.exclusion,
                        patient: this.patient_id,
                    },
                    {onConflict: "patient"}
                ).single();
            this.exclusion = data;
            this.loading = false;
            if (error) {
                this.showToast("Hata", error.message, 5000, "error")
            } else {
                this.showToast("Başarılı", "Gönüllü Hariç Tutma Kriterleri Başarıyla Kaydedildi!", 3000, "success")
                this.step = 4;
                await this.assertEligible();
            }
        },

        removeCameraDiagnosis() {
            Object.entries(this.diagnosis).forEach(([key, value]) => {

                if (key === 'doctor') return

                Object.keys(value).forEach((key2) => {
                    if ((key2.includes('left') || key2.includes('right')) && !(key2.includes('notEvaluated'))) {
                        this.diagnosis[key][key2] = null
                    }
                })
            })
        },

        async getDiagnosesFromEyeCheckup() {
            this.loadingSpinner = true;
            this.removeCameraDiagnosis()
            this.diagnosisItems = []
            this.diagnosisItemsMap = {}
            
            await fetch(`https://${SYSTEM_TYPE}.eyelabel.org/crf/getDiagnoses/` + this.demographics.protocol_no, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + this.$supabase.auth.currentSession.access_token
                }
            })
            .then(response => response.json())
            .then((data) => {
                if(!data.success) {
                    this.showToast("Hata", data.message, 5000, "error")
                    return
                }
                if(data.success) {
                    this.getDiagnosesButtonDisabled = true
                }
                if(data.data.diagnoseResults == null) {
                    this.showToast("Hata", `Analiz sonucu hazır değil veya bulunamadı! Lütfen birkaç dakika bekleyiniz. 
                        Eğer sorun devam ederse Eyecheckup uygulamasından tekrar analize gönderiniz.`, 30000, "error")
                    this.getDiagnosesButtonDisabled = false
                }
                data.data.diagnoseResults.forEach(diagnose => {
                    diagnose.diagnoses.forEach(diagnosis => {
                        if(diagnosis.diagnosisType == 'LASER' || diagnosis.diagnosisType == 'AMD' || diagnosis.diagnosisType == 'HTRP') {
                            return
                        }

                        this.diagnosisItemsMap = {
                            ...this.diagnosisItemsMap,
                            [diagnosis.diagnosisType]: {
                                ...this.diagnosisItemsMap[diagnosis.diagnosisType],
                                diagnosisName: diagnosis.diagnosisType.split('_').join(' '),
                                [`${diagnose.eyePosition == 1 ? 'left' : 'right'}_${diagnose.cameraType.toLowerCase()}`]: 
                                    diagnosis.level ? diagnosis.level : diagnosis.exist == false ? 'YOK' : diagnosis.exist == true ? 'VAR' : diagnosis.exist
                            }
                        }
                        this.diagnosis = {
                            ...this.diagnosis,
                            [diagnose.cameraType.toLowerCase()]: {
                                ...this.diagnosis[diagnose.cameraType.toLowerCase()],
                                [`${diagnose.eyePosition == 1 ? 'left' : 'right'}_has_${diagnosis.diagnosisType.toLowerCase()}`]:
                                    diagnosis.level ? diagnosis.level : diagnosis.exist
                            }
                        }
                    })
                })
                this.diagnosisItems = []
                Object.values(this.diagnosisItemsMap).forEach(diagnosis => {
                    this.diagnosisItems.push(diagnosis)
                })
                
                // Add uploads.left and right object path
                let _left = []
                let _right = []
                data.data.imageInfos.forEach(upload => {
                    if (upload.imageCropType == "MACULA_CROPPED") {
                        return
                    }
                    if(upload.eyePosition == 1) {
                        _left.push({
                            patient: this.patient_id,
                            object: upload.originalImagePath || upload.uploadedName,
                            side: 'left',
                            type: upload.imageCropType,
                            path: this.staticPath + (upload.originalImagePath || upload.uploadedName),
                            centeredObject: upload.centeredObject
                        })
                    } else if(upload.eyePosition == 2) {
                        _right.push({
                            patient: this.patient_id,
                            object: upload.originalImagePath || upload.uploadedName,
                            side: 'right',
                            type: upload.imageCropType,
                            path: this.staticPath + (upload.originalImagePath || upload.uploadedName),
                            centeredObject: upload.centeredObject
                        })
                    }
                })

                this.uploads.left = _left
                this.uploads.right = _right

                this.showToast("Başarılı", "Gönüllü Eyecheckup bilgileri başarıyla getirildi!", 3000, "success")
                this.showToast("Bilgi", "Lütfen Kaydet & Devam butonuna tıklamayı unutmayınız!", 7000, "info")
                this.showToast("Bilgi", "Fotoğraf Yükle aşaması için fotoğraflar hazır, Kaydetmeyi unutmayınız!", 7000, "info")
            })
            this.loadingSpinner = false;
        },
        async upsertEyeCheckup() {
            this.loading = true;
            const { data: topcon, error: errorTopcon } = await this.$supabase
                .from("diagnosis")
                .upsert(
                    {
                        ...this.diagnosis.topcon,
                        type: "topcon",
                        patient: this.patient_id
                    }
                ).single();
            this.diagnosis.topcon = topcon;

            const { data: canon, error: errorCanon } = await this.$supabase
                .from("diagnosis")
                .upsert(
                    {
                        ...this.diagnosis.canon,
                        type: "canon",
                        patient: this.patient_id
                    }
                ).single();
            this.diagnosis.canon = canon;

            const { data: optomed, error: errorOptomed } = await this.$supabase
                .from("diagnosis")
                .upsert(
                    {
                        ...this.diagnosis.optomed,
                        type: "optomed",
                        patient: this.patient_id
                    }
                ).single();
            this.diagnosis.optomed = optomed;
            this.loading = false;

            if (errorTopcon || errorCanon || errorOptomed) {
                this.showToast("Hata", errorTopcon.message, 5000, "error")
                this.showToast("Hata", errorCanon.message, 5000, "error")
                this.showToast("Hata", errorOptomed.message, 5000, "error")
            } else {
                this.showToast("Başarılı", "Gönüllü EyeCheckup Teşhisleri başarıyla kaydedildi!", 3000, "success")
                this.step = 5;
            }
        },

        async upsertUploads() {
            await this.$supabase
                .from("upload")
                .delete()
                .eq("patient", this.patient_id)
            const { error: uploadErrorLeft } = await this.$supabase
                .from("upload")
                .insert(this.uploads.left);

            const { error: uploadErrorRight } = await this.$supabase
                .from("upload")
                .insert(this.uploads.right);

            if (uploadErrorLeft || uploadErrorRight) {
                if (uploadErrorLeft.message.includes('duplicate') || uploadErrorRight.message.includes('duplicate')) {
                    this.showToast("Hata", "Fotoğraflar Kaydedilemedi! Aynı fotoğraflar zaten veritabanında kayıtlı", 5000, "error")
                    return
                }
                this.showToast("Hata", uploadErrorLeft.message, 5000, "error")
                this.showToast("Hata", uploadErrorRight.message, 5000, "error")
            } else {
                this.photosUploaded = true
                this.showToast("Başarılı", "Gönüllü Fundus Fotoğrafları başarıyla kaydedildi!", 3000, "success")
            }
        },

        async upsertDiagnosisModal() {
            if (this.demographics.single_eye) {
                if (this.uploads.left.length == 0) {
                    this.notEvaluated(true, 'left', true, true)
                } else if (this.uploads.right.length == 0) {
                    this.notEvaluated(true, 'right', true, true)
                }
            }

            const _diagnoseNames = Object.keys(this.diagnoseNames).map(diagnose => diagnose)

            this.doctorDiagnoses = { right: [], left: [] }
            Object.entries(this.diagnosis.doctor).forEach(([key, value]) => {
                if ((key.includes('right') || key.includes('left')) && 
                    !((value == false) || (value == 'noDR') || (value == 'noGL') || (value == 'noARMD')) &&
                    _diagnoseNames.includes(key.split('_')[2])) {
                    if(!(this.doctorDiagnoses[key.split('_')[0]].includes(`${this.diagnoseNames[key.split('_')[2]]} - ${String(value).toUpperCase()}`))) {
                        this.doctorDiagnoses[key.split('_')[0]].push(`${this.diagnoseNames[key.split('_')[2]]} - ${this.anomalyNames[String(value).toUpperCase()]}`)
                    }
                }

                if(!key.includes('glaucoma') && 
                    !key.includes('notEvaluated') && 
                    (this.$supabase.auth.currentUser.app_metadata.doctor_type == 'glaucoma') &&
                    key.includes('_has_')) {
                    this.diagnosis.doctor[key] = null
                }
            })
            this.$bvModal.show('modal-scoped')
        },

        async upsertDiagnosis() {
            this.loading = true;
                const {data: doctor, error: doctorError} = await this.$supabase
                    .from("diagnosis")
                    .upsert(
                        {
                            ...this.diagnosis.doctor,
                            type: "doctor",
                            patient: this.patient_id,
                        }
                    ).single();
                this.diagnosis.doctor = doctor;
                if (doctorError) {
                    this.showToast("Hata", doctorError.message, 5000, "error")
                }
            this.loading = false;
            this.step = 7;
            if(this.$store.state.fastEvaluate) {
                    let _patientsArray = this.$store.state.patients;
                    let nextPage;
                    _patientsArray.shift();
                    this.$store.commit('setPatients', _patientsArray)
                    nextPage = this.$store.state.patients[0]?.id ? `/demographics/${this.$store.state.patients[0]?.id}` : `/diagnosis`;
                    this.$router.push(nextPage);
            } else {
                this.$bvModal.show('successModal');
            }
        },
        async updateDiagnoses() {
            this.loading = true;
            const value = await this.$bvModal.msgBoxConfirm(
                "Girdiğiniz tüm bilgileri onaylıyor musunuz?.",
                {
                    title: "Onay",
                    size: "md",
                    buttonSize: "md",
                    okVariant: "success",
                    okTitle: "EVET",
                    cancelTitle: "HAYIR",
                    cancelVariant: "danger",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: false,
                }
            );
            if (value) {
                for (const diagnosis of this.diagnoses) {
                    const {data: lastCommited} = await this.$supabase.from("diagnosis")
                        .select("*")
                        .eq("id", diagnosis.id)
                        .single();

                    if (!Object.keys(diagnosis).every(key => lastCommited[key] === diagnosis[key])) {
                        const {id, ...currrent} = diagnosis;
                        await this.$supabase
                            .from("diagnosis")
                            .update(currrent)
                            .match({id})
                            .single();
                        await this.$supabase.from("signature")
                            .insert([{patient: this.patient_id, signature: "i consent", on_behalf_of: diagnosis.by}])
                            .single();
                        await this.$supabase.from("consentForm")
                            .insert([{patient: this.patient_id, signature: "i consent", on_behalf_of: diagnosis.by}])
                            .single();
                    }
                    this.$bvModal.show('successModal');

                }

            }
            this.loading = false;
        },

        async uploadEye(file, index, side) {
            this.uploadCounter++
            this.loadingSpinner = true
            const bytes = base64.toByteArray(
                file.path.replace(/data:.*;base64,/, "")
            );

            const [mimeType] = /data:image\/(.*);/.exec(file.path);
            const {data: upload, error: uploadError} = await this.$supabase
                .from("upload")
                .insert([
                    {
                        patient: this.patient_id,
                        object: `${this.patient_id}/${side}_${Date.now()}_${index}_${file.name}`,
                        side,
                    },
                ])
                .single();

            if (uploadError) {
                this.showToast("Hata", uploadError.message, 5000, "error")
            }

            await this.$supabase.storage
                .from("eyecheckup")
                .upload(upload.object, new Blob([bytes], {type: mimeType, cacheControl: '3600', upsert: false})) // {type: mimeType, cacheControl: '3600', upsert: false} in docs
                .then((data) => {
                    this.uploadCounter--
                    this.uploadCounter == 0 ? this.loadingSpinner = false : null
                    this.uploads[upload.side].push({...upload, path: this.staticPath + upload.object});
                    if(data.error) {
                        this.showToast("Hata", data.error, 5000, "error")
                    }
                })

            file.object = upload.object;
            file.side = upload.side;
        },

        uploadLeftEye(_, index, files) {
            this.uploadEye(files[index], index, "left");
        },

        uploadRightEye(_, index, files) {
            this.uploadEye(files[index], index, "right");
        },

        showComments(target) {
            this.currentHistoryTarget = target;
            this.$bvModal.show('commentHistoryModal');
        },

        async getCraStatus() {
            const {data: status} = await this.$supabase
                .from("cra_review_status")
                .select("*")
                .eq("patient", this.patient_id);

            this.status = status.reduce((acc, status) => {
                acc[status.target] = status;
                return acc;
            }, {});
        },

        async dontShowWeek() {
            var today = new Date();
            var dd = today.getDate();
            
            const data = {
                st : true, // status
                dt : dd,   // date
            }
            localStorage.setItem('_gnWMd', JSON.stringify(data))
            this.$bvModal.hide('generalWarningModal')
        },

        // check is confirmed and show modal
        async sign() {
            this.confirmed = "";
            const value = await this.$bvModal.msgBoxConfirm(
                "Girdiğiniz tüm bilgileri onaylıyor musunuz?.",
                {
                    title: "Onay",
                    size: "md",
                    buttonSize: "md",
                    okVariant: "success",
                    okTitle: "EVET",
                    cancelTitle: "HAYIR",
                    cancelVariant: "danger",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: false,
                    id: "confirmModal",
                }
            );
            if (value) {
                const {data} = await this.$supabase.from("signature")
                    .insert([{patient: this.patient_id, signature: "i consent"}])
                    .single();

                if (!data.signature && this.patientEligible) {
                    this.error = 'Eksik veya fazla bilgileriniz var, lütfen kontrol edin!';
                    this.$bvModal.show('generalErrorModal');
                } else {
                    this.$bvModal.show('successModal');
                }
            }
        },
        showToast(title, message, timeout, type) {
            this.$toastr.Add({
                name: "demographicsToast", // this is give you ability to use removeByName method
                title: title, // Toast Title
                msg: message, // Toast Message
                clickClose: false, // Click Close Disable
                timeout: timeout, // Remember defaultTimeout is 5 sec.(5000) in this case the toast won't close automatically
                position: "toast-top-right", // Toast Position.
                type: type, // Toast type,
                preventDuplicates: true, //Default is false,
                style: { top: "50px" } // bind inline style to toast  (check [Vue doc](https://vuejs.org/v2/guide/class-and-style.html#Binding-Inline-Styles) for more examples)
            });
        },
        enableAndDisableInputs(formGroupName, disabled) {
            formGroupName.forEach(formGroupName => {
                const formGroup = document.getElementById(formGroupName)
                const inputs = formGroup?.getElementsByTagName('input')
                for (let i = 0; i < inputs?.length; i++) {
                    if (formGroupName.includes('formGroupARMD') && this.demographics.age < 55) {
                        inputs[i].disabled = true
                        continue
                    }
                    inputs[i].disabled = disabled
                }
            })
        },
        notEvaluated(event, eyePosition, macula, opticDisc) {
            if (!event) {
                if (opticDisc) {
                    if (eyePosition == 'left') {
                        this.enableAndDisableInputs(['formGroupGLLeft'], false)
                        this.left_od_not_evaluated = false
                    } else if (eyePosition == 'right') {
                        this.enableAndDisableInputs(['formGroupGLRight'], false)
                        this.right_od_not_evaluated = false
                    }
                }

                if (macula) {
                    if (eyePosition == 'left') {
                        this.enableAndDisableInputs(['formGroupARMDLeft'], false)
                        this.left_macula_not_evaluated = false
                    } else if (eyePosition == 'right') {
                        this.enableAndDisableInputs(['formGroupARMDRight'], false)
                        this.right_macula_not_evaluated = false
                    }
                }

                if (!this.left_od_not_evaluated && !this.left_macula_not_evaluated) {
                    this.enableAndDisableInputs([
                        'formGroupGLLeft',
                        'formGroupARMDLeft',
                        'formGroupDRLeft',
                        'formGroupDMELeft',
                        'formGroupRVOLeft',
                        'formGroupRRLeft'
                    ], false)
                    this.diagnosis.doctor.left_has_not_evaluated = false
                }

                if (!this.right_od_not_evaluated && !this.right_macula_not_evaluated) {
                    this.enableAndDisableInputs([
                        'formGroupGLRight',
                        'formGroupARMDRight',
                        'formGroupDRRight',
                        'formGroupDMERight',
                        'formGroupRVORight',
                        'formGroupRRRight'
                    ], false)
                    this.diagnosis.doctor.right_has_not_evaluated = false
                }
                return
            }

            if (opticDisc) {
                if (eyePosition == 'left') {
                    this.enableAndDisableInputs(['formGroupGLLeft'], true)
                    this.left_od_not_evaluated = true
                } else if (eyePosition == 'right') {
                    this.enableAndDisableInputs(['formGroupGLRight'], true)
                    this.right_od_not_evaluated = true
                }
            } 
            
            if (macula) {
                if (eyePosition == 'left') {
                    this.enableAndDisableInputs(['formGroupARMDLeft'], true)
                    this.left_macula_not_evaluated = true
                } else if (eyePosition == 'right') {
                    this.enableAndDisableInputs(['formGroupARMDRight'], true)
                    this.right_macula_not_evaluated = true
                }
            }

            if (this.left_od_not_evaluated && this.left_macula_not_evaluated) {
                this.enableAndDisableInputs([
                    'formGroupGLLeft',
                    'formGroupARMDLeft',
                    'formGroupDRLeft',
                    'formGroupDMELeft',
                    'formGroupRVOLeft',
                    'formGroupRRLeft'
                ], true)
                this.diagnosis.doctor.left_has_not_evaluated = true
            }

            if (this.right_od_not_evaluated && this.right_macula_not_evaluated) {
                this.enableAndDisableInputs([
                    'formGroupGLRight',
                    'formGroupARMDRight',
                    'formGroupDRRight',
                    'formGroupDMERight',
                    'formGroupRVORight',
                    'formGroupRRRight'
                ], true)
                this.diagnosis.doctor.right_has_not_evaluated = true
            }
        },
        patientAgePopup() {
            if(this.demographics.age < 55) {
                this.showToast("Dikkat", `Bu hasta 55 yaşından küçük. Lütfen Yaşa Bağlı Makula Dejenerasyonu 
                            teşhisini belirlerken bu durumu göz ardı etmeyiniz.`, 10000, "warning")
            }
        }
    },
};
</script>

<style>
.checked-has .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #EA4335 !important;
    border-color: #EA4335 !important;
}

.checked-has .custom-control-input:checked ~ .custom-control-label {
    color: #EA4335 !important;
}

.loading-spinner {
    position: fixed;
    width: 100%;
    height: 100%;    
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #000;
    opacity: 0.5;
    z-index: 1000;
}

.align-middle {
    top: 50%;
    left: 50%;
    z-index: 1001;
    color: #EA4335;
}

.card-header {
    cursor: pointer;
}

.form-area {
    margin: 20px;
}

.modalp {
    font-size: 15px;
}

.float-div-left {
    float: left !important;
}

.float-div-right {
    float: right !important;
}

@media only screen and (max-width: 900px) {
    .float-div-left {
        float: none !important;
        margin: auto;
        width: 75%;
    }

    .float-div-right {
        float: none !important;
        margin: auto;
        width: 75%;
    }

    .form-area {
        margin: 0;
    }

}
.fundus-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.vertical-hr {
    border-right: 1px solid gray;
    height: 100%;
}
#accordion6 > div > div.row > div:nth-child(1) > div.float-div-right > div:nth-child(2) > div > div.image-list-container.display-flex.flex-wrap > div.image-list-item.position-relative.cursor-pointer.display-flex.justify-content-center.align-items-center,
#accordion6 > div > div.row > div:nth-child(2) > div.float-div-left > div:nth-child(2) > div > div.image-list-container.display-flex.flex-wrap > div.image-list-item.position-relative.cursor-pointer.display-flex.justify-content-center.align-items-center,
#accordion6 > div > div.row > div:nth-child(1) > div.float-div-right > div:nth-child(2) > div > div.image-container.position-relative.text-center.image-list > div > div.image-bottom.display-flex.position-absolute.full-width.align-items-center.justify-content-between.justify-content-end,
#accordion6 > div > div.row > div:nth-child(2) > div.float-div-left > div:nth-child(2) > div > div.image-container.position-relative.text-center.image-list > div > div.image-bottom.display-flex.position-absolute.full-width.align-items-center.justify-content-between.justify-content-end {
    display: none;
}
</style>
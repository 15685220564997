<template>
    <div class="app-footer">
        <div class="app-footer__inner">
            <div class="app-footer-right">
                <div class="text-center text-black-50 opacity-8 mt-3">
                    Copyright &copy; WiseCRF 2023
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: "PageFooter",
    };
</script>

import Vue from 'vue'
import './plugins/vuetify'
import router from './router'
import store from './store'
import BootstrapVue from "bootstrap-vue"

import App from './App'
import DialogForms from './DemoPages/Components/DialogForms';
import Default from './Layout/Wrappers/baseLayout.vue';
import Pages from './Layout/Wrappers/pagesLayout.vue';
import Apps from './Layout/Wrappers/appLayout.vue';
import DemographicsPage from './DemoPages/Demographics/Demographics.vue';
import VueKonva from 'vue-konva'
import {BIcon, BIconCheckCircleFill, BIconExclamationCircleFill, BootstrapVueIcons, SpinnerPlugin } from 'bootstrap-vue'
import CommentHistory from "@/DemoPages/Components/CommentHistory";
import VueImg from 'v-img';
import VueFormWizard from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

import VueLazyload from 'vue-lazyload';
import SupabaseVue from 'vue-supabase'
import moment from 'moment';
import VueZoomer from 'vue-zoomer'
Vue.use(VueZoomer)
Vue.use(SpinnerPlugin)
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.component('DialogForms', DialogForms); //
Vue.component('BIcon', BIcon);
Vue.component('BIconCheckCircleFill', BIconCheckCircleFill);
Vue.component('BIconExclamationCircleFill', BIconExclamationCircleFill);
Vue.use(VueFormWizard)
Vue.use(VueImg);
Vue.use(VueKonva)
import VueToastr from "vue-toastr";

Vue.use(VueToastr);

Vue.use(VueLazyload);
Vue.config.productionTip = false;
export const SYSTEM_TYPE =
    window.location.hostname === "www.wisecrf.com" ||
    window.location.hostname === "klinik1.wisecrf.com"
        ? "api"
        : "testapi";
const API_URL =
    window.location.hostname === "www.wisecrf.com"
        ? "https://api.wisecrf.com"
        : window.location.hostname === "klinik1.wisecrf.com"
        ? "https://clinicalapi.wisecrf.com"
        : "https://testapi.wisecrf.com";

Vue.use(SupabaseVue, {
    supabaseUrl: API_URL,
    supabaseKey: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJyb2xlIjoiYW5vbiIsImlhdCI6MTYyNzIwODU0MCwiZXhwIjoxOTc0MzYzNzQwfQ.5S0p-WTijEnGN32sUo92h6YewTPf7gR5LR2zcr6xMPE",
    supabaseOptions: {},
});


Vue.component('default-layout', Default);
Vue.component('comment-history', CommentHistory);
Vue.component('userpages-layout', Pages); //
Vue.component('apps-layout', Apps); //
Vue.component('DemographicsPage', DemographicsPage); //

Vue.filter('formatDate', function(value) {
    if (value) {
        return moment(String(value)).format('DD/MM/YYYY hh:mm ')
    }
});

new Vue({
  el: '#app',
  router,
  store,
  template: '<App/>',
  components: { App }
});

<template>
    <div :class="headerbg" class="app-header header-shadow">
        <div class="logo-src"/>
        <div class="app-header__content">

            <div class="app-header-right">
                <UserArea/>
            </div>
        </div>
    </div>
</template>

<script>


    import UserArea from './Header/HeaderUserArea';

    import {library} from '@fortawesome/fontawesome-svg-core'
    import {
        faEllipsisV,
    } from '@fortawesome/free-solid-svg-icons'

    library.add(
        faEllipsisV,
    );
    export default {
        name: "PageHeader",
        components: {
            UserArea,
        },

        data() {
            return {}
        },
        props: {
            headerbg: String,

        }
    };
</script>
